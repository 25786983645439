<script>
import Vue from 'vue'

import footerLayout from "../../layouts/footerLayout";
import headerLayout from "../../layouts/headerLayout";
// import applyCouponPopup from "../popUpModals/applyCouponPopup";
import deliveryAddressPopup from "../popUpModals/deliveryAddressPopup";
import commonApi from "../apiModel/apiModel";
// import cusinePopup from "../popUpModals/cusinePopup";

import { AlertPlugin } from 'bootstrap-vue'
Vue.use(AlertPlugin)

import { DropdownPlugin } from 'bootstrap-vue'
Vue.use(DropdownPlugin)

import { TooltipPlugin } from 'bootstrap-vue'
Vue.use(TooltipPlugin)

import { FormGroupPlugin } from 'bootstrap-vue'
Vue.use(FormGroupPlugin)

import { FormInputPlugin } from 'bootstrap-vue'
Vue.use(FormInputPlugin)


import ClassOnScroll from 'vue-class-on-scroll'

import VueGoogleAutocomplete from 'vue-google-autocomplete'
//     import carousel from 'vue-owl-carousel'
	import {
  VsaList,
  VsaItem,
  VsaHeading,
  VsaContent,
  VsaIcon
} from 'vue-simple-accordion';
import 'vue-simple-accordion/dist/vue-simple-accordion.css';

 import { IntegerPlusminus } from 'vue-integer-plusminus'

import 'bootstrap-vue/dist/bootstrap-vue.css'

import DatePicker from "vue2-datepicker";

	// import VueDropdown from 'vue-dynamic-dropdown'

// 	  can register the component globally By adding this in main.js

// Vue.component('vue-dropdown', VueDropdown);

export default {
	data() {
      return {
        cusineAddonModalShow:true,
		selectedAddonCartItems:[],
    getOnlinePaymentMethod: "",
      paymentGatewayID: "gpay",

    ccAvenueRestArr:[],
		itemTotalCartModal:'',
		showLoader: 0,
		// isCafeteria:1,
		// walletRedeemDiv:true,
		cusinieAddonsDetails: "",
		cartShimmer: 0,
        userAddressData:'',
		restType:'',
		userAddress:'',
		 deliveryDate: "",
		 deliveryTime:'',
		orderTypeMethod: '',
		PaymentTypeMethod: '',
		PaymentMode: '',
		deliveryAdd: '',
		isDisabled: true,
		nextStep:true,
		 isHidden: true,
		activeTab : 'option0',
		deliver: false,
        terms: false,
		alertText:'',
		alertVariants:'',
		alertHead:'',
		alertShow: false,
		userID: '',
		userMobileNo:null,
		addressType:'',
		completeAddress: '',
		landMark: '',
		corporateBranchID: '',
		deliveryAddressArr:'',
		deliveryAddressID: '',
    getOnlineMethod: '',
		cartDetails: [], //  cart Details.
		cartItemsDetails: [], // Items in cart.
		restaurantDetails: undefined, // Restaurant Details
		orderType: undefined, // all order Types
		paymentType: undefined, // all payment Types 
		coupon:'',
		voucher:'',
		isWalletApplied: 0,
         variants: ['primary', 'secondary', 'success', 'warning', 'danger', 'info', 'light', 'dark', 'white'],
        // headerBgVariant: 'dark',
        // headerTextVariant: 'light',
        // bodyBgVariant: 'light',
        // bodyTextVariant: 'dark',
        footerBgVariant: 'white',
        footerTextVariant: 'dark',
		couponsDetailsArr:[],
		vouchersDetailsArr:[],
        time:'',
		appliedCouponCode: '',
		walletBalance: 0,
		oldWalletBalance: 0,
		voucherApplied: 0,
		voucherDiscount:'',
		cusineDetailsCartDataObj:{}
      }
    },
		 directives: {
  'class-on-scroll': ClassOnScroll,
},

components: {
				headerLayout,
				 footerLayout, 
				//  applyCouponPopup,
				//  carousel,
				//  VueDropdown,
				commonApi,
				DatePicker, 
				 VsaList,
				 VsaItem,
				 VsaHeading,
				 VsaContent,
				 VsaIcon,
				 IntegerPlusminus,
				 VueGoogleAutocomplete,
				 deliveryAddressPopup,
				//  cusinePopup
			  },

// computed: {
//   	isDisabled: function(){
//     	return !this.terms;
//     }
//   },
				 
  mounted()
    { 
      // if (localStorage.getItem('reloaded')) {
      //   var temp1 = localStorage.getItem('if');
      //   temp1 = temp1 + 2;
      //   localStorage.setItem('if', temp1);

      //   // this.alertShow = true;
			// 	// this.alertText = localStorage.getItem('else') + " *** " + localStorage.getItem('if');
			// 	// this.alertVariants = "success";
			// 	// this.alertHead = "Warning"

      //   alert("IF");

			// 	// alert('Please select date and time');
			// 	// return;

      //   // localStorage.setItem('if', '');

      //   console.log("if reload");
      //   // The page was just reloaded. Clear the value from local storage
      //   // so that it will reload the next time this page is visited.
      //   localStorage.removeItem('reloaded');
      // } else {
      //   localStorage.setItem('reloaded', '1');

      //   var temp = localStorage.getItem('else');
      //   temp = temp + 1;
      //   localStorage.setItem('else', temp);
        
      //   // this.alertShow = true;
			// 	// this.alertText = localStorage.getItem('else') + " *** " + localStorage.getItem('if');
			// 	// this.alertVariants = "danger";
			// 	// this.alertHead = "Error"

      //   alert("Else");

			// 	// alert('Please select date and time');
			// 	// return;
      //   console.log("else reload");
      //   // Set a flag so that we know not to reload the page twice.

      //   location.reload();
      // }
		if (localStorage.getItem('uTid') !== null) {
			this.getUserProfile();
			// this.userID = JSON.parse(localStorage.getItem('userData')).userID;
			// this.corporateBranchID = JSON.parse(localStorage.getItem('userData')).corporateBranchID;
			// this.userMobileNo = JSON.parse(localStorage.getItem('userData')).mobileNo;
		}

		const today = new Date();
					// const date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
					this.time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
					// this.getAllCoupons();
					
			this.getCheckoutDetails();
      this.getCartDetails();
			// this.getCheckoutDetails(this.coupon, 0);
    },

	methods: {
    getCartDetails()
		{
			// let getCartDetailsData = JSON.stringify({ userID: this.userID,
			// 										});
			// Vue.axios.post('/getCartDetails', getCartDetailsData)
			Vue.axios.get('/getCartDetails')
			.then((response)=>{
				// console.log(response);
				let status = response.status;
				if(status == 200 && response.data.status == 200)
				{
					if(response.data.cartDetails !== '' && response.data.cartItems !== '')
					{
						this.cartDetailsHead = response.data.cartDetails;
						this.cartItemsDetailsHead = response.data.cartItems;
					}
					else
					{
						this.cartDetailsHead = [];
						this.cartItemsDetailsHead = [];
					}
				}
				// this.cartStatus = response.data.cartdetails;
			}).catch((error) => {
				console.log(error.response.data.message);
			});
		},
    
		makePament()
		{
			this.showLoader = 1;
		},
		
		validateCheckout()
		{
			if(this.deliveryAddressID == "")
			{
				this.alertShow = true;
				this.alertText = "Please select a delivery address";
				this.alertVariants = "danger";
				this.alertHead = "Error"
				// alert('Please select a delivery address');
				return;
			}
			else if(this.orderTypeMethod == '')
			{
				this.alertShow = true;
				this.alertText = "Please select a order type";
				this.alertVariants = "danger";
				this.alertHead = "Error"

				// alert('Please select a order type');
				return;
			}
			else if ((this.accessOrderEnum == 'schedule_pickup' || this.accessOrderEnum == 'schedule_delivery') && this.deliveryDate == '' && this.deliveryTime == '') 
			{
				this.alertShow = true;
				this.alertText = "Please select date and time";
				this.alertVariants = "danger";
				this.alertHead = "Error"

				// alert('Please select date and time');
				return;
			}
			else if (this.PaymentTypeMethod == '')
			{
				this.alertShow = true;
				this.alertText = "Please select a payment method";
				this.alertVariants = "danger";
				this.alertHead = "Error"

				// alert('Please select a payment method');
				return;
			}
      else if (this.paymentGatewayID == '') 
      {
        this.alertShow = true;
				this.alertText = "Please Select Payment Option";
				this.alertVariants = "danger";
				this.alertHead = "Error";
				return;
      }

      this.$analytics.logEvent('order_place', { 
          userID: localStorage.getItem('userID'), 
          userName: localStorage.getItem('userName'),
          deliveryAddressID: this.deliveryAddressID,
          orderTypeMethod: this.orderTypeMethod,
          PaymentTypeMethod: this.PaymentTypeMethod,
      }, { 'debug_mode':true })
		},

		addOnsModal(cartID, cuisineID, restBranchID)
		{
			let getCuisineDetails = JSON.stringify({ 
				cartID: cartID,
                cuisineID: cuisineID,
                restBranchID: restBranchID
				});
			this.axios.post('/getCuisineDetailsForCart', getCuisineDetails)
			.then((response)=>{
			// console.log(response.data.cuisineDetails);
			let status = response.status;
			if(status == 200 && response.data.status == 200)
			{
                this.cusinieAddonsDetails = response.data;

				/////////// to get total sum of item from cart ///////////////
				this.itemFromCartModal = this.cusinieAddonsDetails.cuisineDetail.quantity;
				this.selectedAddonCartItems = response.data.cartSelectedAddonsArr;
				var sum = this.selectedAddonCartItems.reduce((acc, item) => acc + item.price, 0);
				this.itemTotalCartModal = (parseInt(this.cusinieAddonsDetails.cuisineDetail.price) + parseInt(sum) )* parseInt(this.itemFromCartModal);
				/////////// to get total sum of item from cart ///////////////
				// this.cusineDetailsCartDataObj.itemTotalCartModal = itemTotalCartModal;
				// this.cusineDetailsCartDataObj.selectedAddonCartItems = selectedAddonCartItems;


                // console.log(this.cusineDetailsDataArr);
                this.$bvModal.show("modal-cuisinePopup");
            }
            }).catch((error) => {
			    console.log(error.response.data.message);
			});
			// this.cusinieAddonsDetails = {
			// 	"cartID":cartID,
			// 	"cuisineID":cuisineID,
			// 	"restBranchID":restBranchID,
			// };
			// this.cuisineDetails = cusinePopup.methods.getCuisineDetails;
			// this.cuisineDetails();
		},

		selectCartModalAddons(itemPrice)
		{
			// console.log(this.selectedAddonCartItems);
			// console.log(itemPrice);
			var sum = this.selectedAddonCartItems.reduce((acc, item) => acc + item.price, 0);
			this.itemTotalCartModal = (parseInt(itemPrice) + parseInt(sum)) * parseInt(this.itemFromCartModal);
		},

		updateCartDataFromModal(cusineDetailsDataArr)
		{
			// console.log(cusineDetailsDataArr);
			var sum = this.selectedAddonCartItems.reduce((acc, item) => acc + item.price, 0);
			this.itemTotalCartModal = (parseInt(cusineDetailsDataArr.price) + parseInt(sum) )* parseInt(this.itemFromCartModal);
		},

		getAllCoupons()
		{
			if(this.appliedCouponCode == '')
			{
				this.getCouponsByIDCommonApi = commonApi.getCouponsByIDApi;
				// this.getCouponsByIDCommonApi(this.userID, this.cartDetails[0].restBranchID);
				this.getCouponsByIDCommonApi(this.cartDetails[0].restBranchID, this.cartDetails[0].mealTypeID);
				this.$bvModal.show('modal-coupon');

        this.$analytics.logEvent('apply_coupon', { 
            userID: localStorage.getItem('userID'), 
            userName: localStorage.getItem('userName'),
            text: 'Apply Coupon',
        }, { 'debug_mode':true })
			}
		},
		removeCoupon()
		{
			this.$confirm("Your applied coupon will be removed, Do you want to remove the applied coupon?","Do you want to remove applied coupon?","")
				.then(() => {
								this.appliedCouponCode = '';
								this.coupon = '';
								// this.getCheckoutDetails(this.coupon, 0);
								this.getCheckoutDetails();
							});
		},

		alertError(section)
		{
			this.alertShow = true;
				this.alertText = section+" is not applicable";
				this.alertVariants = "danger";
				this.alertHead = "Error"
				return;
		},
		
		redeemWallet()
		{
			if(this.walletBalance == 0)
			{
				alert('Your wallet balance is insufficient');
			}
			else
			{
				//  if($walletBalance >= $cartDetailsArr[0]['totalamount'])
                //         {
                //             $walletBalance = round($walletBalance - $cartDetailsArr[0]['totalamount']);
                //             $newCartTotal = 0;
                //             $walletDiscount = $cartDetailsArr[0]['totalamount'];
                //             // $walletBalance = $newCartTotal; 
                //         }
                //         else if($cartDetailsArr[0]['totalamount'] >=  $walletBalance)
                //         {
                //             $walletDiscount = $walletBalance;
                //             $newCartTotal = round($cartDetailsArr[0]['totalamount'] - $walletBalance);
                //             $walletBalance = 0;

                //         }

				if(this.walletBalance >= this.cartDetails[0].totalamount)
				{
					var newWalletBalance = this.walletBalance - this.cartDetails[0].totalamount; 
					var redeemptionPoints = this.cartDetails[0].totalamount;
				}
				else if (this.cartDetails[0].totalamount >= this.walletBalance) 
				{
					var newWalletBalance = 0;	
					var redeemptionPoints = this.walletBalance;
				}
				this.$confirm("Your current wallet balance is "+ this.walletBalance +" points, balance after redemption will be "+ newWalletBalance +" points.","Do you want to redeem "+ redeemptionPoints +" points from your wallet?","")
				.then(() => {
								this.oldWalletBalance = this.walletBalance;
								this.isWalletApplied = 1;
								this.getCheckoutDetails();
				});
			}

      this.$analytics.logEvent('apply_wallet', { 
          userID: localStorage.getItem('userID'), 
          userName: localStorage.getItem('userName'),
          text: 'Apply Wallet',
      }, { 'debug_mode':true })
		},
		removeWallet()
		{
			this.$confirm("","Do you want to remove wallet points?","")
				.then(() => {
								this.isWalletApplied = 0;
								// this.getCheckoutDetails(this.coupon, 1);
								this.getCheckoutDetails();
							});
		},

		applyCoupon(couponCode)
    {
			this.coupon = couponCode;
      // this.getCheckoutDetails(couponCode, 0);
      this.getCheckoutDetails();
      
      this.$analytics.logEvent('coupon_select', { 
          userID: localStorage.getItem('userID'), 
          userName: localStorage.getItem('userName'),
          couponCode: this.coupon,
      }, { 'debug_mode':true })
    },
		applyVoucher(voucherCode)
		{
			this.voucher = voucherCode;
      this.getCheckoutDetails();

      this.$analytics.logEvent('voucher_select', { 
          userID: localStorage.getItem('userID'), 
          userName: localStorage.getItem('userName'),
          voucherCode: this.voucher,
      }, { 'debug_mode':true })
		},
		removeVoucher()
		{
			this.$confirm("","Do you want to remove applied voucher?","")
				.then(() => {
								this.voucherApplied = 0;
								this.voucher = '';
								// this.getCheckoutDetails(this.coupon, 1);
								this.getCheckoutDetails();
							});
		},

		getAllVouchers()
		{
			this.getVouchersByIDCommonApi = commonApi.getVouchersByIDApi;
      // this.getCouponsByIDCommonApi(this.userID, this.cartDetails[0].restBranchID);
			this.getVouchersByIDCommonApi(this.cartDetails[0].restBranchID);
			this.$bvModal.show('modal-voucher');

      this.$analytics.logEvent('apply_voucher', { 
          userID: localStorage.getItem('userID'), 
          userName: localStorage.getItem('userName'),
          text: 'Apply Voucher',
      }, { 'debug_mode':true })
		},

		getUserProfile()
		{
			this.axios.get('/getUserProfile')
            .then((res)=>{
                // console.log(res);
                let status = res.status;
                if(status == 200 && res.data.status==200)
                {
                    this.userID = res.data.userData.userID;
            		this.userMobileNo = res.data.userData.mobileNo;
                }else{
                    console.log(res.data.message);
                }
            });
		},

		disabledBeforeToday(date)
		{
			const today = new Date();
			today.setHours(0, 0, 0, 0);

			return date < today ;
		},

		addressModal()
        {
            this.userAddressData = "";
            this.$bvModal.show("modal-addAddress");
        },

		// getCheckoutDetails(couponDetails, isWalletApplied)
		getCheckoutDetails()
		{
			this.cartShimmer = 0;
			// alert(couponDetails);
			// let getCheckoutDetailsData = JSON.stringify({ userID: this.userID,
			// 										});
			// Vue.axios.post('/checkOutDetails', getCheckoutDetailsData)
			var couponCode = "";
			if(this.coupon != null || this.coupon != undefined || this.coupon != '')
			{
				var couponCode = this.coupon;
			}
			var walletApplied = 0;
			if(this.isWalletApplied != 0)
			{
				var walletApplied = this.isWalletApplied;
			}
			var voucherCode = "";
			if(this.voucher != null || this.voucher != undefined || this.voucher != '')
			{
				var voucherCode = this.voucher;
			}
			
			Vue.axios.get('/checkOutDetails', {
												params: {
													couponCode: couponCode,
													isWalletApplied:walletApplied,
													voucherCode: voucherCode,
												}
			})
				.then((response)=>{
					this.cartShimmer = 1;
					// console.log(response);
					let status = response.status;
					if(status == 200 && response.data.status == 200)
					{
						// alert("in");
						// console.log(response.data.cartDetails);
                             this.$bvModal.hide('modal-coupon');
                             this.$bvModal.hide('modal-voucher');
							 if (response.data.couponCode) {
								 this.appliedCouponCode = response.data.couponCode;
								 
							 }
              this.ccAvenueRestArr = response.data.ccAvenueRestArr;
							this.cartDetails = response.data.cartDetails;
							this.cartItemsDetails = response.data.cartItems;
							this.restaurantDetails = response.data.restaurantDetails;
							this.voucherApplied = response.data.voucherApplied;
							this.voucherDetails = response.data.voucherDetails;
							this.walletBalance = response.data.userWalletBalance;
							this.walletDiscount = response.data.walletDiscount;
							this.isWalletApplied = response.data.walletApplied;
							this.orderType = response.data.orderType;
							this.paymentType = response.data.paymentType;
							this.restType = this.cartDetails[0].restType;
							this.userAddress = response.data.userAddresses;
							if((this.restType == 2 || this.restType == 3 || (this.cartDetails.length != 0 && this.cartDetails[0].mealTypeID == -2)) && this.restaurantDetails != '')
							{
								this.deliveryAddressArr = this.restaurantDetails;
								// this.deliveryAddressType = this.restaurantDetails.deliveryAddressType;
								this.deliveryAddressID = this.restaurantDetails.restaurantAddressID;
								this.nextStep = false;
							}
							if (this.isWalletApplied == 1) // Wallet + COD, Wallet + Online
							{
								this.PaymentMode = "Wallet";
								if (this.cartDetails.length != 0 && this.cartDetails[0].totalCorporateSubsidy > 0)  // Subsidy, Subsidy + COD, Subsidy+ wallet
								{
									this.PaymentMode = "Subsidy + Wallet";
									if (this.voucherApplied == 1) {
										this.PaymentMode = "Subsidy + Pre-buy voucher + Wallet";
									}
								}
							}
							else if(this.voucherApplied == 1) // Pre-buy voucher
							{
								this.PaymentMode = "Pre-buy voucher";
								if (this.isWalletApplied == 1)  // Wallet + Pre-buy
								{
									this.PaymentMode = "Wallet + Pre-buy voucher"
								}
								else if (this.cartDetails.length != 0 && this.cartDetails[0].totalCorporateSubsidy > 0)
								{
									this.PaymentMode = "Subsidy + Pre-buy voucher";
								}
							}
							else if (this.cartDetails.length != 0 && this.cartDetails[0].totalCorporateSubsidy > 0)  // Subsidy, Subsidy + COD, Subsidy+ wallet
							{
								this.PaymentMode = "Subsidy";
							}

							// console.log(this.orderType.length);	
							// console.log(this.paymentType);
							if(this.orderTypeMethod && this.orderTypeMethod != '')
							{
								this.orderTypeMethod = this.orderTypeMethod;
							}
							else if(this.orderType.length >= 0)
							{
								this.accessOrderEnum = this.orderType[0].access;
								this.orderTypeMethod = this.orderType[0].checkOutPaymentID;
							}
							if(this.PaymentTypeMethod && this.PaymentTypeMethod != '')
							{
								this.PaymentTypeMethod = this.PaymentTypeMethod;
							}
							else if(this.paymentType.length >= 0)
							{
								this.accessPaymentEnum = this.paymentType[0].access;
								this.PaymentTypeMethod = this.paymentType[0].checkOutPaymentID;
							}
							// this.toalAmoutCheckOut = cartDetails[0].totalamount;
					}
				}).catch((error) => {
					this.cartShimmer = 1;
						console.log(error.response.data.message);
				});
		},

		updateItemAddonModal(cusineDetailsDataArr)
		{
			this.cusineAddonModalShow = false;
			// console.log(cusineDetailsDataArr);
			var sum = this.selectedAddonCartItems.reduce((acc, item) => acc + item.price, 0);
			this.itemTotalCartModal = (parseInt(cusineDetailsDataArr.price) + parseInt(sum) )* parseInt(this.itemFromCartModal);
			this.addItemToCartCommonApi = commonApi.addItemTocartApi;
			this.arrayColumn = commonApi.arrayColumn;
			var selectedAddonCartItemsIDArr = this.arrayColumn(this.selectedAddonCartItems, 'addOnItemID');

			var newQuantity = this.itemFromCartModal;
			var itemDishID = cusineDetailsDataArr.id;
			var restBranchID = cusineDetailsDataArr.restaurantid;

			if(this.itemFromCartModal < cusineDetailsDataArr.minorderquantity)
			{
				this.$confirm("Are you sure, you want to remove this item from cart. Minimum order quantity requires "+ cusineDetailsDataArr.minorderquantity+ "","Remove Item?","")
				.then(() => {
								var newQuantity = 0;
								let getCartDetailsData = JSON.stringify({ 
																		userID: this.userID,
																		dishID: itemDishID,
																		quantity: newQuantity,
																		restBID: restBranchID,
																		addOnItems:selectedAddonCartItemsIDArr,
																		});
								this.selectedAddOnItems = []; // null value of previously selected addons
								Vue.axios.post('/updateCart', getCartDetailsData)
									.then((response)=>{
										// console.log(response);
										let status = response.status;
										if(status == 200 && response.data.status == 200)
										{
												// this.getCheckoutDetails(this.coupon, 0);
												// this.getCheckoutDetails();
												// this.getCafeMenuByID();
												location.reload();
										}
										// this.cartStatus = response.data.cartdetails;
									}).catch((error) => {
											console.log(error.response.data.message);
									});
				})
				.catch(error => {
						cusineDetailsDataArr.quantity = cusineDetailsDataArr.minorderquantity;
						});
			}
			else
			{
				let getCartDetailsData = JSON.stringify({ userID: this.userID,
														dishID: itemDishID,
														quantity: newQuantity,
														restBID: restBranchID,
														addOnItems:selectedAddonCartItemsIDArr,
															});
								this.selectedAddOnItems = []; // null value of previously selected addons
								Vue.axios.post('/updateCart', getCartDetailsData)
									.then((response)=>{
										// console.log(response);
										let status = response.status;
										if(status == 200 && response.data.status == 200)
										{
												// this.getCheckoutDetails(this.coupon, 0);
												// this.getCheckoutDetails();
												// this.getCafeMenuByID();
												location.reload();
										}
										// this.cartStatus = response.data.cartdetails;
									}).catch((error) => {
											console.log(error.response.data.message);
									});
			}
		},

		
		updateItemToCart(cusineDataArr, itemFrom) // itemFrom = 0=> from card, 1=>cart, 2=>modal 
		{	
			// console.log(typeIndex);
			// console.log(cusineDataArr);
			// console.log(itemFrom);
			// console.log(this.list[typeIndex].data[cusineIndex].quantity);
			// console.log(this.list.data.id.indexOf(cusineDataArr.cuisineID));
			if(itemFrom == 1)
			{
				var newQuantity = cusineDataArr.quantity;
				var itemDishID = cusineDataArr.cuisineID;
				var restBranchID = cusineDataArr.restBranchID;
			}
			else if(itemFrom == 2)
			{
				var newQuantity = cusineDataArr.quantity;
				var itemDishID = cusineDataArr.id;
				var restBranchID = cusineDataArr.restaurantid;
			}

			if((cusineDataArr.mealType == 2 || cusineDataArr.mealType == 3) && cusineDataArr.quantity < cusineDataArr.minorderquantity)
			{
				this.$confirm("Are you sure, you want to remove this item from cart. Minimum order quantity requires "+ cusineDataArr.minorderquantity+ " pax","Remove Item?","")
				.then(() => {
								var newQuantity = 0;
								let getCartDetailsData = JSON.stringify({ userID: this.userID,
																		dishID: itemDishID,
																		quantity: newQuantity,
																		restBID: restBranchID,
																		});
								this.selectedAddOnItems = []; // null value of previously selected addons
								Vue.axios.post('/updateCart', getCartDetailsData)
									.then((response)=>{
										// console.log(response);
										let status = response.status;
										if(status == 200 && response.data.status == 200)
										{
												// this.getCheckoutDetails(this.coupon, 0);
												this.getCheckoutDetails();
												// this.getCafeMenuByID();
										}
										// this.cartStatus = response.data.cartdetails;
									}).catch((error) => {
											console.log(error.response.data.message);
									});
				})
				.catch(error => {
						cusineDataArr.quantity = cusineDataArr.minorderquantity;
						});
			}
			else
			{
				let getCartDetailsData = JSON.stringify({ userID: this.userID,
															dishID: itemDishID,
															quantity: newQuantity,
															restBID: restBranchID,
															});
								this.selectedAddOnItems = []; // null value of previously selected addons
								Vue.axios.post('/updateCart', getCartDetailsData)
									.then((response)=>{
										// console.log(response);
										let status = response.status;
										if(status == 200 && response.data.status == 200)
										{
												// this.getCheckoutDetails(this.coupon, 0);
												this.getCheckoutDetails();
												// this.getCafeMenuByID();
										}
										// this.cartStatus = response.data.cartdetails;
									}).catch((error) => {
											console.log(error.response.data.message);
									});
			}
		},

    updateItemToCartEvent(cusineDataArr)
    {
        this.$analytics.logEvent('update_cart', { 
            userID: localStorage.getItem('userID'), 
            userName: localStorage.getItem('userName'),
            itemID: JSON.stringify(cusineDataArr.id),
            itemName: JSON.stringify(cusineDataArr.name),
            itemPrice: JSON.stringify(cusineDataArr.price),
            itemQuantity: JSON.stringify(cusineDataArr.quantity),
            itemMealType: JSON.stringify(cusineDataArr.meal_type),
            itemRestID: JSON.stringify(cusineDataArr.restaurantid),
            itemRestName: JSON.stringify(cusineDataArr.restaurantName),
        }, { 'debug_mode':true })
    },

		getAddressForDelivery(index)
		{
			this.deliveryAddressArr = this.userAddress[index];
			// this.deliveryAddressType = this.userAddress[index].deliveryAddressType;
			this.deliveryAddressID = this.userAddress[index].userAddID;
			this.nextStep = false;
			// alert(this.deliveryAddressID);

      this.$analytics.logEvent('delivery_address', { 
          userID: localStorage.getItem('userID'), 
          userName: localStorage.getItem('userName'),
          deliveryAddressID: this.deliveryAddressID,
      }, { 'debug_mode':true })
		},

		getOrderType(index, checkoutID)
		{
			this.accessOrderEnum = this.orderType[index].access;
			this.orderTypeMethod = checkoutID;
		},

		getPaymentType(index, checkoutID)
		{
			this.accessPaymentEnum = this.paymentType[index].access;
			this.PaymentTypeMethod = checkoutID;
      if (this.accessPaymentEnum == "COD") {
        this.paymentGatewayID = 1;
      }
		},
		getCheckOutAccess()
		{
			// alert();
			this.$bvModal.show("modal-mobile");
		},
    getOnlinePaymentOption(event) {
      this.getOnlineMethod = event.target.value;
      if (this.getOnlineMethod == "gpay") {
        this.getOnlinePaymentMethod = "upi";
        this.getbankCode = "TEZ";

        this.$analytics.logEvent('payment_type', { 
            userID: localStorage.getItem('userID'), 
            userName: localStorage.getItem('userName'),
            paymentPatner: 'ccAvenue',
            paymentOption: 'Google Pay',
        }, { 'debug_mode':true })
      } else if (this.getOnlineMethod == "phonepe") {
        this.getOnlinePaymentMethod = "cashcard";
        this.getbankCode = "PHONEPE";

        this.$analytics.logEvent('payment_type', { 
            userID: localStorage.getItem('userID'), 
            userName: localStorage.getItem('userName'),
            paymentPatner: 'ccAvenue',
            paymentOption: 'Phone Pay',
        }, { 'debug_mode':true })
      } else if (this.getOnlineMethod == "paytm") {
        this.getOnlinePaymentMethod = "cashcard";
        this.getbankCode = "PAYTM";

        this.$analytics.logEvent('payment_type', { 
            userID: localStorage.getItem('userID'), 
            userName: localStorage.getItem('userName'),
            paymentPatner: 'payU',
            paymentOption: 'Paytm',
        }, { 'debug_mode':true })
      } else if (this.getOnlineMethod == "creditcard") {
        this.getOnlinePaymentMethod = "CC";
        this.getbankCode = "";

        this.$analytics.logEvent('payment_type', { 
            userID: localStorage.getItem('userID'), 
            userName: localStorage.getItem('userName'),
            paymentPatner: 'payU',
            paymentOption: 'Credit Card',
        }, { 'debug_mode':true })
      } else if (this.getOnlineMethod == "debitcard") {
        this.getOnlinePaymentMethod = "DC";
        this.getbankCode = "";

        this.$analytics.logEvent('payment_type', { 
            userID: localStorage.getItem('userID'), 
            userName: localStorage.getItem('userName'),
            paymentPatner: 'payU',
            paymentOption: 'Debit Card',
        }, { 'debug_mode':true })
      } else if (this.getOnlineMethod == "others") {
        this.getOnlinePaymentMethod = "";
        this.getbankCode = "";

        this.$analytics.logEvent('payment_type', { 
            userID: localStorage.getItem('userID'), 
            userName: localStorage.getItem('userName'),
            paymentPatner: 'payU',
            paymentOption: 'Others',
        }, { 'debug_mode':true })
      }
    },
	},
	
}
</script>

<template>
    <div class="container-fuild m-0 p-0">

		<div class="alertMsg">
			<b-alert dismissible v-model="alertShow" :variant="alertVariants">
				<h4 class="alert-heading">{{alertHead}}</h4>
				<p>
				{{alertText}}
				</p>
			</b-alert>
		</div>
        <headerLayout />
		<!-- header Layout -->
		<deliveryAddressPopup :userAddressData="this.userAddressData"/>
		<!-- <cusinePopup :cusinieAddonsDetails="this.cusineDetailsCartDataObj"/> -->
		<!-- <applyCouponPopup :cartDetails="cartDetails" :cartItemsDetails="cartItemsDetails"/> -->

        <main>
            <div class="bg_gray">
		    <div class="container margin_detail">
		        <div class="row">
		            <div class="col-lg-8 list_menu">
		                <div id="reviews">
					        <div class="review_card">
					            <div class="row">
					                <div class="col-md-6 review_content">
					                    <div class="clearfix">
					                        <h3 v-if="restType != 2 && restType != 3 && (cartDetails.length != 0 && cartDetails[0].mealTypeID != -2)" class="cardTitle">Delivery Address</h3>
					                        <h3 v-else-if="restType == 2 || restType == 3 || (cartDetails.length != 0 && cartDetails[0].mealTypeID == -2)" class="cardTitle">Pickup From</h3>
					                        <h3 v-else class="cardTitle">Address</h3>
					                    </div>
					                </div>
                                    <div class="col-md-6 review_content align-right" v-if="restType != 2 && restType != 3 && (cartDetails.length != 0 && cartDetails[0].mealTypeID != -2)">
					                    <div class="clearfix" v-if="isHidden">
					                       <button class="btn btn-danger btnNewAdd" @click="addressModal">+ Add New Address</button>
					                    </div>
										<a href="#" v-if="!isHidden" v-on:click="isHidden = !isHidden" class="customAnch">Change</a>
					                </div>
					            </div>
					            <!-- /row -->
					            <div class="row reply"  v-if="isHidden && restType != 2 && restType != 3 && (cartDetails.length != 0 && cartDetails[0].mealTypeID != -2) && (userAddress != '' || userAddress.length != 0)">
					                <div class="col-md-6 otherAdd" v-for="(addresses,index) in userAddress" v-bind:key="addresses">
					                    <div class="deliveryCard align-left">
                                            <div class="addressType">
                                                <h5>{{addresses.deliveryAddressType}}</h5>
                                            </div>
                                            <!-- / address Type -->

                                            <div class="address">
                                                <p class="two-line-text">{{addresses.deliveryAddress}}</p>
                                            </div>
                                            <!--  /address -->

                                            <div class="deliverBtn align-right" v-if="addresses.isDeliverable == 1">
					                            <button class="btn_1" @click="getAddressForDelivery(index)" v-on:click="isHidden = !isHidden">Deliver here</button>
                                            </div>
                                            <!-- /delivery Btn -->
											 <div class="deliverBtn align-right" v-if="addresses.isDeliverable == 0">
					                            <button :disabled='isDisabled' class="btn_1 disabledBtn">Does Not Deliver here</button>
                                            </div>

                                        </div>
                                        <!-- / delivery Card -->
					                </div>
                                    <!-- /col -->
					            </div>
					            <!-- /reply -->

								<!-- Delivery DIv -->
								<div class="row reply" v-if="!isHidden && deliveryAddressArr != '' && restType != 2 && restType != 3 && (cartDetails.length != 0 && cartDetails[0].mealTypeID != -2)">
									<div class="col-md-12">
					                    <div class="deliveryCard align-left">
                                            <div class="addressType">
                                                <h5>{{deliveryAddressArr.deliveryAddressType}}</h5>
                                            </div>
                                            <!-- / address Type -->

                                             <div class="address">
                                                <p class="one-line-text">{{ deliveryAddressArr.deliveryAddress}} </p>
                                            </div>
                                            <!--  /address -->

                                        </div>
                                        <!-- / delivery Card -->
					                </div>
                                    <!-- /col -->
								</div>

								<!-- Cafeteria Div-->
								<div class="row reply" v-if="restType == 2 || restType == 3 || (cartDetails.length != 0 && cartDetails[0].mealTypeID == -2)">
									<div class="col-md-12">
					                    <div class="deliveryCard align-left" v-if="restaurantDetails">
                                            <div class="addressType">
                                                <h5>{{deliveryAddressArr.deliveryAddressType}}</h5>
                                            </div>
                                            <!-- / address Type -->

                                            <div class="address" :class="{ 'one-line-text': !nextStep }">
                                                <span>{{deliveryAddressArr.deliveryAddress}}</span>
                                            </div>
                                            <!--  /address -->

                                        </div>
                                        <!-- / delivery Card -->
										<!-- <div class="deliverBtn align-right">
					                            <button class="btn_1" v-if="nextStep" v-on:click="nextStep = !nextStep">Next</button>
                                            </div> -->
                                            <!-- /delivery Btn -->
					                </div>
                                    <!-- /col -->
								</div>
                               
					        </div>
					        <!-- /review_card -->
					    </div>
					    <!-- /reviews -->
                        <div class="orderTypeDiv">
							<div id="reviews">
								<div class="review_card">
									<div class="row" style="cursor:pointer;" v-if="nextStep" v-on:click="nextStep = !nextStep">
										<div class="col-md-6 review_content">
											<div class="clearfix">
												<h3 class="cardTitle titleHead">Order Type</h3>
											</div>
										</div>
										<div class="col-md-6 align-right">
												<i class="arrow_carrot-down" style="font-size: 30px;line-height: 45px;"></i>
										</div>
										
									</div>
									<!-- /row -->
									<div v-if="!nextStep">
											<div class="row">
												<div class="col-md-12 review_content">
													<div class="clearfix">
														<h3 class="cardTitle titleHead">Order Type</h3>
													</div>
												</div>
												<div class="col-md-4 col-sm-12 col-xs-12 otherAdd" v-for="(orderTypeData,index) in orderType" v-bind:key="orderTypeData.checkOutPaymentID">
													<label class="container_radio expandHead ordertypeHead">{{orderTypeData.displayName}}
														<!-- <input type="radio" :checked="index == 0 ? true : false"  v-model="orderTypeMethod" :value="orderTypeData.checkOutPaymentID" name="opt_order"> -->
														<input type="radio" :checked="index == 0 ? true : false" @click="getOrderType(index,orderTypeData.checkOutPaymentID)" name="opt_order">
														<span class="checkmark"></span>
													</label>
													<div class="row" v-if="orderTypeData.description != ''">
														<div class="ordertypeBody expandBody col-md-8 col-sm-12 col-xs-12">
															<span>{{orderTypeData.description}}</span>
														</div>
													</div>
												</div>
											</div>
											<hr>
											<div v-if="accessOrderEnum === 'schedule_pickup' || accessOrderEnum === 'schedule_delivery'">
												<p class="optionHeading">Schedule Pickup</p>
												<div class="row">
													<div class="col-md-6 col-lg-6 col-xl-6">
														<p class="optionContentText">Select pickup Date:</p>
															<date-picker v-model="deliveryDate" format="DD-MMM-YYYY" :disabled-date=" disabledBeforeToday" :first-day-of-week="1" lang="en"></date-picker>
													</div>
													<div class="col-md-6 col-lg-6 col-xl-6">
														<p class="optionContentText">Select Time:</p>
															<b-form-input
															id="time"
															value="13:45:00"
															type="time"
															v-model="deliveryTime"
															></b-form-input>
													</div>
												</div>
											</div>
											<!-- <div v-if="accessOrderEnum === 'subscription_pickup' || accessOrderEnum === 'subscription_delivery'">
												<p class="optionHeading">Subscribe</p>
												<div class="row">
													<div class="col-md-6 col-lg-6 col-xl-6">
														<p class="optionContentText">Select pickup Date: (You can select multiple dates)</p>
															<date-picker v-model="deliveryDate" :first-day-of-week="1" lang="en"></date-picker>
													</div>
													<div class="col-md-6 col-lg-6 col-xl-6">
														<p class="optionContentText">Select Time:</p>
															<b-form-input
															id="time"
															value="13:45:00"
															type="time"
															v-model="deliveryTime"
															></b-form-input>
													</div>
												</div>
											</div> -->
									</div>
								
								</div>
					        		<!-- /review_card -->
					    	</div>
                   		 </div>
                        <!-- / orderTypeDiv -->
                        <div class="paymentTypeDiv">
                            <div id="reviews">
								<div class="review_card">
									<div class="row" style="cursor:pointer;" v-if="nextStep" v-on:click="nextStep = !nextStep">
										<div class="col-md-6 review_content">
											<div class="clearfix">
												<h3 class="cardTitle titleHead">Payment Type</h3>
											</div>
										</div>

										<div class="col-md-6 align-right">
												<i class="arrow_carrot-down" style="font-size: 30px;line-height: 45px;"></i>
										</div>
										
									</div>
									<!-- /row -->
									<div  v-if="!nextStep">
									<div class="row">
										<div class="col-md-12 review_content">
											<div class="clearfix">
												<h3 class="cardTitle titleHead">Payment Type</h3>
											</div>
										</div>
                     <div
                          class="otherAdd"
                          v-for="(payTypeData) in paymentType"
                          v-bind:key="payTypeData.checkOutPaymentID"
                        >
										<!-- <div class="col-lg-12 col-sm-6 otherAdd" v-for="(payTypeData) in paymentType" v-bind:key="payTypeData.checkOutPaymentID"> -->
											<!-- <div>
												<label class="card-radio-label">
												<input
													type="radio"
													name="pay-method"
													id="pay-methodoption3"
													class="card-radio-input"
													:value="payTypeData.checkOutPaymentID"
													:checked="index == 0 ? true : false"
													@click="getPaymentType(index,payTypeData.checkOutPaymentID)"
												/>

												<span class="card-radio text-center text-truncate">
													<i class="uil d-block h2 mb-3" :class="payTypeData.icon"></i>
													{{payTypeData.displayName}}
													<br>
													<span v-if="payTypeData.description != ''">
														{{payTypeData.description}}
													</span>
												</span>
												</label>
											</div> -->
                      <!-- if online -->
                            <div
                              class="row"
                              v-if="
                                payTypeData.access == 'online' &&
                                accessPaymentEnum == 'online'
                              "
                            >
                              <!-- PayU Button -->
                              <!-- <div class="col-4">
                            <div>
                              <label class="card-radio-label">
                              <input
                                type="radio"
                                name="payment-Gateway-method"
                                id="pay-gateway-methodoption3"
                                class="card-radio-input"
                                :checked="true"
                                v-model="paymentGatewayID"
                                value="1"
                              />
                              <span class="card-radio text-center text-truncate">
                                <img src="../../templateAssests/img/ckgallery/payU.png" height="25" width="auto">
                              </span>
                              </label>
                            </div>
                          </div> -->
                              <!-- CC Avenue Button -->
                              <!-- <div class="col-4">
                            <div>
                              <label class="card-radio-label">
                              <input
                                type="radio"
                                name="payment-Gateway-method"
                                id="pay-gateway-methodoption3"
                                class="card-radio-input"
                                v-model="paymentGatewayID"
                                value="2"
                              />
                              <span class="card-radio text-center text-truncate">
                                  <img src="../../templateAssests/img/ckgallery/ccavenue.png" width="50" height="auto">
                                <br>
                              </span>
                              </label>
                            </div>
                          </div> -->
                              <!-- Google Pay Button -->
                              <div
                                class="col-lg-2 col-sm-6 onlinePayCardMobile"
                              >
                                <div>
                                  <label class="card-radio-label">
                                    <input
                                      type="radio"
                                      name="payment-Gateway-method"
                                      id="pay-gateway-methodoption3"
                                      class="card-radio-input"
                                      v-model="paymentGatewayID"
                                      value="gpay"
                                      @click="getOnlinePaymentOption"
                                      :checked="true"
                                    />
                                    <span
                                      class="
                                        card-radio
                                        text-center text-truncate
                                        onlinePayButton
                                      "
                                    >
                                      <!-- <i class="uil d-block h2 mb-3" :class="payTypeData.icon"></i> -->
                                      <img
                                        src="../../templateAssests/img/ckgallery/UPI_logo.png"
                                        width="50"
                                        height="auto"
                                      />
                                      <br />
                                      <span>UPI</span>
                                    </span>
                                  </label>
                                </div>
                              </div>
                              <!-- Phonepe Button -->
                              <!-- <div
                                class="col-lg-2 col-sm-6 onlinePayCardMobile"
                              >
                                <div>
                                  <label class="card-radio-label">
                                    <input
                                      type="radio"
                                      name="payment-Gateway-method"
                                      id="pay-gateway-methodoption3"
                                      class="card-radio-input"
                                      v-model="paymentGatewayID"
                                      value="phonepe"
                                      @click="getOnlinePaymentOption"
                                    />

                                    <span
                                      class="
                                        card-radio
                                        text-center text-truncate
                                        onlinePayButton
                                      "
                                    >
                                      <img
                                        src="../../templateAssests/img/ckgallery/phonepay.png"
                                        width="50"
                                        height="auto"
                                      />
                                      <br />
                                      <span>Phone Pay</span>
                                    </span>
                                  </label>
                                </div>
                              </div> -->
                              <!-- Paytm Button -->
                              <div
                                class="col-lg-2 col-sm-6 onlinePayCardMobile"
                              >
                                <div>
                                  <label class="card-radio-label">
                                    <input
                                      type="radio"
                                      name="payment-Gateway-method"
                                      id="pay-gateway-methodoption3"
                                      class="card-radio-input"
                                      v-model="paymentGatewayID"
                                      value="paytm"
                                      @click="getOnlinePaymentOption"
                                    />

                                    <span
                                      class="
                                        card-radio
                                        text-center text-truncate
                                        onlinePayButton
                                      "
                                    >
                                      <!-- <i class="uil d-block h2 mb-3" :class="payTypeData.icon"></i> -->
                                      <img
                                        src="../../templateAssests/img/ckgallery/paytm.png"
                                        class="mb-3"
                                        width="50"
                                        height="auto"
                                      />
                                      <br />
                                      <span>Paytm</span>
                                    </span>
                                  </label>
                                </div>
                              </div>
                              <!-- Credit Card Button -->
                              <div
                                class="col-lg-2 col-sm-6 onlinePayCardMobile"
                              >
                                <div>
                                  <label class="card-radio-label">
                                    <input
                                      type="radio"
                                      name="payment-Gateway-method"
                                      id="pay-gateway-methodoption3"
                                      class="card-radio-input"
                                      v-model="paymentGatewayID"
                                      value="creditcard"
                                      @click="getOnlinePaymentOption"
                                    />
                                    <span
                                      class="
                                        card-radio
                                        text-center text-truncate
                                        onlinePayButton
                                      "
                                    >
                                      <!-- <i
                                        class="uil d-block h2 mb-3 fa fa-credit-card"
                                      ></i> -->
                                      <img
                                        src="../../templateAssests/img/ckgallery/credit-card.png"
                                        width="50"
                                        height="auto"
                                      />
                                      <br />
                                      <small>Credit Card</small>
                                      <br />
                                      <!-- <span v-if="payTypeData.description != ''">
                                  {{payTypeData.description}}
                                </span> -->
                                    </span>
                                  </label>
                                </div>
                              </div>
                              <!-- Debit Card Button -->
                              <div
                                class="col-lg-2 col-sm-6 onlinePayCardMobile"
                              >
                                <div>
                                  <label class="card-radio-label">
                                    <input
                                      type="radio"
                                      name="payment-Gateway-method"
                                      id="pay-gateway-methodoption3"
                                      class="card-radio-input"
                                      v-model="paymentGatewayID"
                                      value="debitcard"
                                      @click="getOnlinePaymentOption"
                                    />
                                    <span
                                      class="
                                        card-radio
                                        text-center text-truncate
                                        onlinePayButton
                                      "
                                    >
                                      <!-- <i class="uil d-block h2 mb-3 fa fa-credit-card"></i> -->
                                      <img
                                        src="../../templateAssests/img/ckgallery/debit-card.png"
                                        width="50"
                                        height="auto"
                                      />
                                      <br />
                                      <small>Debit Card</small>
                                      <br />
                                      <!-- <span v-if="payTypeData.description != ''">
                                  {{payTypeData.description}}
                                </span> -->
                                    </span>
                                  </label>
                                </div>
                              </div>
                              <!-- Others -->
                              <div
                                class="col-lg-2 col-sm-6 onlinePayCardMobile"
                              >
                                <div>
                                  <label class="card-radio-label">
                                    <input
                                      type="radio"
                                      name="payment-Gateway-method"
                                      id="pay-gateway-methodoption3"
                                      class="card-radio-input"
                                      v-model="paymentGatewayID"
                                      value="others"
                                      @click="getOnlinePaymentOption"
                                    />
                                    <span
                                      class="
                                        card-radio
                                        text-center text-truncate
                                        onlinePayButton
                                      "
                                    >
                                      <i class="uil d-block h2 mb-3 fa fa-credit-card"></i>
                                      <!-- <img src="../../templateAssests/img/ckgallery/paytm.png" width="50" height="auto"> -->
                                      <small>Others</small>
                                      <br />
                                      <!-- <span v-if="payTypeData.description != ''">
                                  {{payTypeData.description}}
                                </span> -->
                                    </span>
                                  </label>
                                </div>
                              </div>
                            </div>
                            <!-- end if Online -->
											</div>

									</div>
									</div>
								
								</div>
					        		<!-- /review_card -->
					    	</div>
                        </div>
                        <!-- / paymentTypeDiv -->
		            </div>
		            <!-- /col -->
		            <div class="col-lg-4" :class="$isExtMob ? 'mobListExt' : ''" id="sidebar_fixed">
						<div class="box_order mobile_fixed" v-if="cartShimmer == 0">
		                    
		                    <div class="main mainCart">
								<div class="cartItems align-center">
									<h4 class="align-center"><VShimmerLine width="48" /></h4>
									<div class="row" v-for="n in 3" v-bind:key="n" style="margin-top:10px;">
										<div class="col-6">
											<VShimmerLine width="70" />
										</div>
										<div class="col-3">
											<VShimmerLine width="48" />
										</div>
										<div class="col-3">
											<VShimmerLine width="48" />
										</div>
									</div>
									<div class="row" style="margin-top:10px;">
										<div class="col-6" v-for="o in 2" v-bind:key="o">
											<VShimmerBlock width="98" heightPx="75" />
										</div>
									</div>

									<div class="row" v-for="p in 3" v-bind:key="p" style="margin-top:10px;">
										<div class="col-10">
											<VShimmerLine width="70" />
										</div>
										<div class="col-2">
											<VShimmerLine width="48" />
										</div>
									</div>

									<p style="margin-top:10px;"><VShimmerBlock heightPx="38" /></p>
								</div>
								
								
								
		                    </div>
		                </div>

						<!-- shimmer end -->
						<div class="box_order mobile_fixed" v-else-if="!cartDetails.length && cartShimmer == 1">
		                    
		                    <div class="main mainCart">
								<div class="cartItems align-center">
									<h4>No Item in Cart</h4>
									<img src="../../templateAssests/img/ckgallery/emptybag.png">
								</div>
								
								
		                    </div>
		                </div>
		                <!-- /box_order -->
		                <div class="box_order mobile_fixed" v-else>
		                    <div class="head">
		                       <div class="row">
								   <div>
										<img :src="cartDetails[0].restImage == null || cartDetails[0].restImage == '' ? 'img' : cartDetails[0].restImage" onerror="this.src='https://assets.cloudkitch.co.in/user_documents/Placeholder.png';" width="55" height="55" style="width:55px; max-height:55px;object-fit:cover;float: left;margin-right: 10px;" alt="thumb" class="lazy">
									<!-- </div> -->
									<!-- / col -->
									<!-- <div class="col-md-10 col-lg-10 col-xl-10"> -->
										<!-- <div  class="restaurantsDetails"> -->
											<h5 v-if="cartDetails != undefined && cartDetails[0].length !== 0">{{cartDetails[0].restName}}</h5>
											<h5 v-else>Restaurant</h5>
										<!-- </div> -->
										<!-- <div  class="locationDetails"> -->
											<p v-if="restaurantDetails">{{restaurantDetails.restaurantBranchName}}</p>
										<!-- </div> -->
									</div>
								   
							   </div>
		                    </div>
		                    <!-- /head -->
							<div class="main mainCart">
								<div class="cartItems">
									<h4>Cart Items 
										<small v-if="cartDetails != undefined && cartDetails[0].length !== 0">({{cartDetails[0].cardcount}})</small>
										<small v-else>(0)</small>
									</h4>
								</div>

									<div v-if="cartItemsDetails != undefined" class="cartTotalItemDetails">
										<div class="row" v-for="ItemsInCart in cartItemsDetails" v-bind:key="ItemsInCart.cartID" style="margin-bottom:10px;">
										<div class="col-8 col-lg-8 col-xl-8 dishName">
											<h5>{{ItemsInCart.cuisineName}} <div class="foodTypeContent align-center" :style="{background : foodType.background}" v-for="foodType in ItemsInCart.type" v-bind:key="foodType.name" :id="foodType.name"  v-b-tooltip.hover="foodType.name" :title="foodType.name"></div></h5>
											<a v-if="ItemsInCart.isCustomisable == 1" @click="addOnsModal(ItemsInCart.cartID, ItemsInCart.cuisineID, ItemsInCart.restBranchID)" class="customAnch customizeAddons"><p style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;margin-bottom:0px;font-size:12px;color:#F3766A;">Customize add on</p></a>
											<!-- <a v-if="ItemsInCart.isCustomisable == 1" class="customAnch customizeAddons"><p style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;margin-bottom:0px;font-size:12px;color:#F3766A;">Customize add on</p></a> -->
										</div>
										<!-- / col -->
										<div class="col-2 col-lg-2 col-xl-2 dishDetails align-right">
											<!-- <input style="border-radius:18px;" type="number" class="form-control" value="1"/> -->
											<integer-plusminus  v-on:click.native="updateItemToCart(ItemsInCart, 1), updateItemToCartEvent(ItemsInCart)"  v-model="ItemsInCart.quantity" :min="0" :max="ItemsInCart.mealType == -2 ? 1 : 100" :step="1">
											<!-- <integer-plusminus  v-on:click.native="updateItemToCart(ItemsInCart, 1)"  v-model="ItemsInCart.quantity" :min="0" :max="100" :step="1"> -->
												<!-- {{ItemsInCart.quantity}} -->
											</integer-plusminus>
										</div>
										<!-- / col -->
										<div class="col-2 col-lg-2 col-xl-2 dishDetails align-left">
											<div class="dishRate">₹{{ItemsInCart.itemTotal}}</div>
										</div>
										<!-- / col -->
										<!-- <hr class="hrBorder"> -->
									</div>
									<!-- /row  -->
									</div>
									 <ul class="clearfix cartPrice" v-if="cartDetails[0].totalCorporateSubsidy > 0">
		                            <li>Corporate subsidy<span>- ₹{{cartDetails[0].totalCorporateSubsidy}}</span></li>
		                            <!-- <li><u id="tax">Tax</u><sup style="color:#f3766a;">*</sup><span>₹{{cartDetails[0].tax_amount}}</span></li>
		                            <li><u id="otherCharges">Other Charges</u><sup style="color:#f3766a;">*</sup><span>₹{{cartDetails[0].totalOcharge}}</span></li> -->
		                        	</ul>
								<!-- <div class="row rewardsSection">
									<div style="cursor:pointer;" class="col-md-4 col-lg-4 col-xl-4 couponsSection rewardsSectiondiv" @click="getAllCoupons">
										<div class="align-center"><img src="../../templateAssests/img/ckgallery/coupon.png" height="30" width="30"></div>
										<p class="rewardHeading align-center">Apply Coupon</p>
										<p class="rewardDesc align-center">Redeem Promo</p>
									</div>
									<div style="cursor:pointer;" class="col-md-4 col-lg-4 col-xl-4 couponsSection rewardsSectiondiv" @click="redeemWallet"> -->
									
									
									<!-- <div style="cursor:pointer;" class="col-md-4 col-lg-4 col-xl-4 couponsSection rewardsSectiondiv" v-on:click="walletRedeemDiv = !walletRedeemDiv"> -->
										
										
										<!-- <div class="align-center"><img src="../../templateAssests/img/ckgallery/wallet1.png" height="30" width="30"></div>
										<p class="rewardHeading align-center">Redeem Wallet<img v-if="isWalletApplied > 0" src="../../templateAssests/img/ckgallery/check.png" height="10" width="10" style="margin-left:3px;object-fit:contain;"></p> -->
										
										
										<!-- <p class="rewardDesc align-center">Balance: ₹5000</p> -->
										<!-- <p class="rewardDesc align-center">Coming Soon...</p> -->

<!-- 										
										<p class="rewardDesc align-center">Balance: ₹{{walletBalance}}</p>
									</div>
									<div class="col-md-4 col-lg-4 col-xl-4 couponsSection rewardsSectiondiv">
										<div class="align-center"><img src="../../templateAssests/img/ckgallery/coupon.png" height="30" width="30"></div>
										<p class="rewardHeading align-center">Loyalty Points</p> -->
										
										
										<!-- <p class="rewardDesc align-center">Points: 2000</p> -->
										
										
										<!-- <p class="rewardDesc align-center">Coming soon...</p>
									</div> -->


									<!-- <div class="col-md-3 col-lg-3 col-xl-3 couponsSection">
										<img src="../../templateAssests/img/ckgallery/coupon.png" height="25" width="25">
										<span class="couponText">Apply Coupon</span>
									</div>
									<div class="col-md-3 col-lg-3 col-xl-3 walletSection">
										<img src="../../templateAssests/img/ckgallery/wallet1.png" height="25" width="25">
										<span class="walletText">Wallet Balance: ₹1560</span>
										<a href="#" calss="walletRedeem customAnch" style="color:#f3766a!important">REDEEM</a>
									</div> -->
									<!-- / col -->


								<!-- </div> -->
								<!-- /row  -->
								<!-- <div class="col-md-12 col-lg-12 col-xl-12 couponsSection" v-if="appliedCouponCode != ''" style="max-width:none;">
										<img src="../../templateAssests/img/ckgallery/coupon.png" height="25" width="25">
										<span class="walletText">Code {{appliedCouponCode}} applied!</span>
										<a @click="removeCoupon" calss="walletRedeem customAnch" style="float:right;color:#f3766a!important;cursor:pointer;">REMOVE</a>
									</div> -->
									<!-- <div v-if="!walletRedeemDiv" class="col-md-12 col-lg-12 col-xl-12 couponsSection" style="max-width:none;">
										<img src="../../templateAssests/img/ckgallery/wallet1.png" height="25" width="25">
										<span class="walletText">Balance: ₹{{walletBalance}}</span>
										<a v-if="walletDiscount <= 0" @click="redeemWallet" calss="walletRedeem customAnch" style="float:right;color:#f3766a!important;cursor:pointer;">REDEEM</a>
										<a v-else-if="walletDiscount > 0" @click="removeWallet" calss="walletRedeem customAnch" style="float:right;color:#f3766a!important;cursor:pointer;">REMOVE</a>
									</div> -->
									

									<!-- Applied Discount Section  -->
									<div class="row rewardsSection">

										<!-- coupon Section-->
										<div @click="getAllCoupons" v-if="appliedCouponCode == ''" style="cursor:pointer;max-width:none;" class="col-md-4 col-lg-4 col-xl-4 couponsSection rewardsSectiondiv">
											<div>
												<img src="../../templateAssests/img/ckgallery/couponSolid.png" height="25" width="25" style="object:fit:contain;">
												<img src="../../templateAssests/img/ckgallery/blankRadio.png" height="16" width="16" style="float:right;object:fit:contain;">
											</div>
											<p class="rewardHeading">Apply Coupon</p>
											<p class="rewardDesc">Promo Applied</p>
										</div>

										<div v-if="appliedCouponCode != ''"  @click="removeCoupon" style="cursor:pointer;max-width:none;" class="col-md-4 col-lg-4 col-xl-4 couponsSection rewardsSectiondiv">
											<div>
												<img src="../../templateAssests/img/ckgallery/couponSolid.png" height="25" width="25" style="object:fit:contain;">
												<img src="../../templateAssests/img/ckgallery/checkRed.png" height="16" width="16" style="float:right;object:fit:contain;">
											</div>
											<p class="rewardHeading">{{appliedCouponCode}}</p>
											<p class="rewardDesc">Promo Applied</p>
										</div>
										<!-- /coupon Section-->

										<!-- Wallet Section -->
										<div v-if="isWalletApplied == 0 && (restType == 2 || restType == 3 || (cartDetails.length != 0 && cartDetails[0].mealTypeID == -2))" @click="redeemWallet" style="cursor:pointer;max-width:none;" class="col-md-4 col-lg-4 col-xl-4 couponsSection rewardsSectiondiv">
											<div>
												<img src="../../templateAssests/img/ckgallery/walletSolid.png" height="25" width="25">
												<img src="../../templateAssests/img/ckgallery/blankRadio.png" height="16" width="16" style="float:right;object:fit:contain;">
											</div>
											<p class="rewardHeading">Redeem Wallet</p>
											<p class="rewardDesc">Balance: {{walletBalance}} Points </p>
										</div>

										<div v-if="isWalletApplied == 0 && (restType == 1 && (cartDetails.length != 0 && cartDetails[0].mealTypeID != -2))" @click="alertError('Wallet')" style="cursor:pointer;max-width:none;" class="col-md-4 col-lg-4 col-xl-4 couponsSection rewardsSectiondiv">
											<div>
												<img src="../../templateAssests/img/ckgallery/walletSolid.png" height="25" width="25">
												<img src="../../templateAssests/img/ckgallery/blankRadio.png" height="16" width="16" style="float:right;object:fit:contain;">
											</div>
											<p class="rewardHeading">Redeem Wallet</p>
											<p class="rewardDesc">Balance: {{walletBalance}} Points </p>
										</div>

										<div v-if="isWalletApplied == 1" @click="removeWallet" style="cursor:pointer;max-width:none;" class="col-md-4 col-lg-4 col-xl-4 couponsSection rewardsSectiondiv">
											<div>
												<img src="../../templateAssests/img/ckgallery/walletSolid.png" height="25" width="25">
												<img src="../../templateAssests/img/ckgallery/checkRed.png" height="16" width="16" style="float:right;object:fit:contain;">
											</div>
											<p class="rewardHeading">{{walletDiscount}} / {{oldWalletBalance}} Points</p>
											<p class="rewardDesc">Wallet Redeemed</p>
										</div>
										<!-- / Wallet Section -->

										<div v-if="voucherApplied == 0" @click="getAllVouchers" style="cursor:pointer;max-width:none;" class="col-md-4 col-lg-4 col-xl-4 couponsSection rewardsSectiondiv">
											<div>
												<img src="../../templateAssests/img/ckgallery/coupon.png" height="25" width="25" style="object:fit:contain;">
												<img src="../../templateAssests/img/ckgallery/blankRadio.png" height="16" width="16" style="float:right;object:fit:contain;">
											</div>
											<p class="rewardHeading">Apply Voucher</p>
											<p class="rewardDesc">Promo Applied</p>
										</div>

										<div v-if="voucherApplied == 1" @click="removeVoucher" style="cursor:pointer;max-width:none;" class="col-md-4 col-lg-4 col-xl-4 couponsSection rewardsSectiondiv">
											<div>
												<img src="../../templateAssests/img/ckgallery/coupon.png" height="25" width="25" style="object:fit:contain;">
												<img src="../../templateAssests/img/ckgallery/checkRed.png" height="16" width="16" style="float:right;object:fit:contain;">
											</div>
											<p class="rewardHeading">{{this.voucher}}</p>
											<p class="rewardDesc">Promo Applied</p>
										</div>
										<!--  / Voucher section -->
									</div>
									<!-- / Applied Discount Section  -->
									
									
									<!-- Apply Discount Section  -->
									<!-- <div class="col-md-12 col-lg-12 col-xl-12 couponsSection" @click="getAllCoupons" v-if="appliedCouponCode == ''" style="max-width:none;cursor:pointer;">
										<img src="../../templateAssests/img/ckgallery/coupon.png" height="25" width="25">
										<span class="walletText">Apply Coupon</span>
										<span style="float:right;font-size:16px;"><i class="uil uil-angle-right-b"></i></span>
									</div> -->
									<!-- <div v-if="isWalletApplied == 0" class="col-md-12 col-lg-12 col-xl-12 couponsSection" style="max-width:none;margin-bottom:16px;cursor:pointer;">
										<img src="../../templateAssests/img/ckgallery/wallet1.png" height="25" width="25">
										<span class="walletText">Balance: ₹{{walletBalance}}</span>
										<a @click="redeemWallet" calss="walletRedeem customAnch" style="float:right;color:#f3766a!important;cursor:pointer;">REDEEM</a>
									</div> -->
									<!-- / Apply Discount Section  -->
									
		                        <ul class="clearfix cartPrice">
		                            <li>Item Total<span>₹{{cartDetails[0].totalitemprice}}</span></li>
		                            <li v-if="voucherApplied == 1 && voucherDetails.voucherType == 2">Voucher Redeemption  (#{{voucherDetails.voucherIdentifier}})<span>- ₹{{voucherDetails.voucherDiscount}}</span></li>
		                            <li v-if="cartDetails[0].couponDiscount && cartDetails[0].couponDiscount != 0" style="color:#A4827D;">Discount ({{appliedCouponCode}}) <span>- ₹{{cartDetails[0].couponDiscount}}</span></li>
		                            <!-- <li v-if="walletDiscount && walletDiscount != 0">Wallet Amount <span>- ₹{{walletDiscount}}</span></li> -->
		                            <li><u  id="TaxandCharges">Taxes & Charges</u><sup style="color:#f3766a;">*</sup><span>₹{{cartDetails[0].tax_amount}}</span></li>
		                            <li v-if="voucherApplied == 1 && voucherDetails.voucherType == 1">Voucher Redeemption  (#{{voucherDetails.voucherIdentifier}})<span>- ₹{{voucherDetails.voucherDiscount}}</span></li>
		                            <li v-if="isWalletApplied == 1">Wallet Redeemption ({{walletDiscount}} Pts) <span>- {{walletDiscount}} Pts</span></li>
		                            <!-- <li><u id="otherCharges">Other Charges</u><sup style="color:#f3766a;">*</sup><span>₹{{Math.round(cartDetails[0].totalOcharge)}}</span></li> -->
		                        </ul>
								
								<!-- / Tooltips for charges -->
								 <b-tooltip target="TaxandCharges" triggers="hover" placement="right">
									<div class="row">
										<div class="col-12 align-left mb_5">
											<div class="row" v-if="cartDetails[0].cgst > 0">
												<div class="col-6">
													<span>CGST</span>
												</div>
												<div class="col-6 align-right">
													<span>₹{{cartDetails[0].cgst}}</span>
												</div>
											</div>
											<div class="row" v-if="cartDetails[0].sgst > 0">
												<div class="col-6">
													<span>SGST</span>
												</div>
												<div class="col-6 align-right">
													<span>₹{{cartDetails[0].sgst}}</span>
												</div>
											</div>
											<div class="row" v-if="cartDetails[0].igst > 0">
												<div class="col-6">
													<span>IGST</span>
												</div>
												<div class="col-6 align-right">
													<span>₹{{cartDetails[0].igst}}</span>
												</div>
											</div>
											<div class="row" v-if="cartDetails[0].totaldcharge > 0">
												<div class="col-6">
													<span>Delivery Charges</span>
												</div>
												<div class="col-6 align-right">
													<span>₹{{cartDetails[0].totaldcharge}}</span>
												</div>
											</div>
											<div class="row" v-if="cartDetails[0].totalrcharge > 0">
												<div class="col-6">
													<span>Restaurants Charges</span>
												</div>
												<div class="col-6 align-right">
													<span>₹{{cartDetails[0].totalrcharge}}</span>
												</div>
											</div>
										</div>
										<div class="col-8">
										</div>
										<div class="col-4">
										</div>
									</div>
								</b-tooltip>
								<b-tooltip target="otherCharges" triggers="hover" placement="right">
									<div class="row">
										<div class="col-12 align-left mb_5">
											<div class="row">
												<div class="col-8">
													<span>Delivery Charges</span>
												</div>
												<div class="col-4 align-right">
													<span>₹{{cartDetails[0].totaldcharge}}</span>
												</div>
											</div>
											<div class="row">
												<div class="col-8">
													<span>Restaurants Charges</span>
												</div>
												<div class="col-4 align-right">
													<span>₹{{cartDetails[0].totalrcharge}}</span>
												</div>
											</div>
										</div>
									</div>
								</b-tooltip>
								<!-- / Tooltips for charges -->

		                        <div class="row opt_order">
									<ul class="totalPrice">
										<!-- {{cartDetails}} -->
		                            <li class="total">Total<span>₹{{cartDetails[0].totalamount}}</span></li>
									</ul>
		                        </div>
		                        <div v-if="(userMobileNo == null || userMobileNo == '') && orderTypeMethod != '' && PaymentTypeMethod != '' && deliveryAddressID != ''" class="btn_1_mobile">
		                            	<button @click="getCheckOutAccess" class="btn_1 full-width mb_5" style="color:#000!important;padding:14px 24px;font-size:16px;"><span>Add Mobile Number</span></button>
								</div>
		                        <div v-else class="btn_1_mobile">
		                            <!-- <div v-if="restaurantDetails.restBranchID == 23"> -->

                    <div v-if="ccAvenueRestArr && ccAvenueRestArr.includes(restaurantDetails.restBranchID)">
                      <!-- For Payu Gateway -->
                      <form
                        :action="
                          paymentGatewayID == 'gpay'
                            ? $ApiUrl + '/ccRequest'
                            : paymentGatewayID == 'phonepe'
                            ? $ApiUrl + '/ccRequest'
                            : paymentGatewayID == 'paytm'
                            ? $ApiUrl + '/initOrder'
                            : paymentGatewayID == 'creditcard'
                            ? $ApiUrl + '/initOrder'
                            : paymentGatewayID == 'debitcard'
                            ? $ApiUrl + '/initOrder'
                            : paymentGatewayID == 'others'
                            ? $ApiUrl + '/initOrder'
                            : $ApiUrl + '/initOrder'
                        "
                        name="payuform"
                        method="POST"
                      >
                        <input
                          type="hidden"
                          :value="userID"
                          name="userID"
                          id="userID"
                        />
                        <input
                          type="hidden"
                          :value="PaymentTypeMethod"
                          name="paymentMethod"
                          id="paymentMethod"
                        />
                        <!-- <div v-if="getOnlineMethod == paytm"> -->
                        <input
                          type="hidden"
                          :value="this.getOnlinePaymentMethod"
                          name="pg"
                          id="pg"
                        />
                        <input
                          type="hidden"
                          :value="this.getbankCode"
                          name="bankcode"
                          id="bankcode"
                        />
                        <!-- </div>
                        <div v-else> -->
                        <input
                          type="hidden"
                          :value="this.getOnlinePaymentMethod"
                          name="enforce_paymode"
                          id="enforce_paymode"
                        />
                        <!-- </div> -->
                        <input
                          type="hidden"
                          :value="PaymentMode"
                          name="PaymentMode"
                          id="PaymentMode"
                        />
                        <input
                          type="hidden"
                          :value="orderTypeMethod"
                          name="orderType"
                          id="orderType"
                        />
                        <input
                          type="hidden"
                          :value="deliveryDate"
                          name="deliveryDate"
                          id="deliveryDate"
                        />
                        <input
                          type="hidden"
                          :value="deliveryTime"
                          name="deliveryTime"
                          id="deliveryTime"
                        />
                        <input
                          type="hidden"
                          value="1"
                          name="platform"
                          id="platform"
                        />
                        <input
                          type="hidden"
                          :value="restaurantDetails.restBranchID"
                          name="restBranchID"
                          id="restBranchID"
                        />
                        <input
                          type="hidden"
                          :value="deliveryAddressID"
                          name="deliveryAddress"
                          id="deliveryAddress"
                        />
                        <input
                          type="hidden"
                          v-if="appliedCouponCode != ''"
                          :value="appliedCouponCode"
                          name="appliedCouponCode"
                          id="appliedCouponCode"
                        />
                        <input
                          type="hidden"
                          v-if="isWalletApplied != 0"
                          :value="isWalletApplied"
                          name="isWalletApplied"
                          id="isWalletApplied"
                        />
                        <input
                          type="hidden"
                          v-if="
                            voucherDetails != '' &&
                            voucherDetails.voucherIdentifier != ''
                          "
                          :value="voucherDetails.voucherIdentifier"
                          name="appliedvoucherIdentifier"
                          id="appliedvoucherIdentifier"
                        />
                        <div
                          v-if="
                            orderTypeMethod != '' &&
                            PaymentTypeMethod != '' &&
                            deliveryAddressID != ''
                          "
                        >
                          <button
                            v-if="
                              (accessOrderEnum == 'schedule_pickup' ||
                                accessOrderEnum == 'schedule_delivery') &&
                              deliveryDate != '' &&
                              deliveryTime != ''
                            "
                            type="submit"
                            class="btn_1 full-width mb_5"
                            @click="validateCheckout"
                            style="
                              background: #ffc60c;
                              color: #000 !important;
                              padding: 14px 24px;
                              font-size: 16px;
                            "
                          >
                            <span
                              v-if="
                                accessPaymentEnum == 'online' &&
                                cartDetails[0].totalamount > 0
                              "
                              >Pay ₹{{ cartDetails[0].totalamount }}</span
                            ><span v-else>Place Order</span>
                          </button>
                          <button
                            v-else-if="
                              accessOrderEnum == 'deliver_now' ||
                              accessOrderEnum == 'pickup' ||
                              accessOrderEnum == 'dinenow'
                            "
                            type="submit"
                            class="btn_1 full-width mb_5"
                            @click="validateCheckout"
                            style="
                              background: #ffc60c;
                              color: #000 !important;
                              padding: 14px 24px;
                              font-size: 16px;
                            "
                          >
                            <span
                              v-if="
                                accessPaymentEnum == 'online' &&
                                cartDetails[0].totalamount > 0
                              "
                              >Pay ₹{{ cartDetails[0].totalamount }}</span
                            ><span v-else>Place Order</span>
                          </button>
                          <span
                            v-else
                            @click="validateCheckout"
                            class="btn_1 full-width mb_5"
                            style="
                              background: #ffc60c;
                              color: #000 !important;
                              padding: 14px 24px;
                              font-size: 16px;
                            "
                            >Pay ₹{{ cartDetails[0].totalamount }}</span
                          >
                        </div>
                        <div v-else>
                          <span
                            @click="validateCheckout"
                            class="btn_1 full-width mb_5"
                            style="
                              background: #ffc60c;
                              color: #000 !important;
                              padding: 14px 24px;
                              font-size: 16px;
                            "
                            >Pay ₹{{ cartDetails[0].totalamount }}</span
                          >
                        </div>
                      </form>
                    </div>
                    <div v-else>
                      <form
                        :action="
                          paymentGatewayID == 'gpay'
                            ? $ApiUrl + '/initOrder'
                            : paymentGatewayID == 'phonepe'
                            ? $ApiUrl + '/initOrder'
                            : paymentGatewayID == 'paytm'
                            ? $ApiUrl + '/initOrder'
                            : paymentGatewayID == 'creditcard'
                            ? $ApiUrl + '/initOrder'
                            : paymentGatewayID == 'debitcard'
                            ? $ApiUrl + '/initOrder'
                            : paymentGatewayID == 'others'
                            ? $ApiUrl + '/initOrder'
                            : $ApiUrl + '/initOrder'
                        "
                        name="payuform"
                        method="POST"
                      >
                        <input
                          type="hidden"
                          :value="userID"
                          name="userID"
                          id="userID"
                        />
                        <input
                          type="hidden"
                          :value="PaymentTypeMethod"
                          name="paymentMethod"
                          id="paymentMethod"
                        />
                        <!-- <div v-if="getOnlineMethod == paytm"> -->
                        <input
                          type="hidden"
                          :value="this.getOnlinePaymentMethod"
                          name="pg"
                          id="pg"
                        />
                        <input
                          type="hidden"
                          :value="this.getbankCode"
                          name="bankcode"
                          id="bankcode"
                        />
                        <!-- </div>
                        <div v-else> -->
                        <input
                          type="hidden"
                          :value="this.getOnlinePaymentMethod"
                          name="enforce_paymode"
                          id="enforce_paymode"
                        />
                        <!-- </div> -->
                        <input
                          type="hidden"
                          :value="PaymentMode"
                          name="PaymentMode"
                          id="PaymentMode"
                        />
                        <input
                          type="hidden"
                          :value="orderTypeMethod"
                          name="orderType"
                          id="orderType"
                        />
                        <input
                          type="hidden"
                          :value="deliveryDate"
                          name="deliveryDate"
                          id="deliveryDate"
                        />
                        <input
                          type="hidden"
                          :value="deliveryTime"
                          name="deliveryTime"
                          id="deliveryTime"
                        />
                        <input
                          type="hidden"
                          value="1"
                          name="platform"
                          id="platform"
                        />
                        <input
                          type="hidden"
                          :value="restaurantDetails.restBranchID"
                          name="restBranchID"
                          id="restBranchID"
                        />
                        <input
                          type="hidden"
                          :value="deliveryAddressID"
                          name="deliveryAddress"
                          id="deliveryAddress"
                        />
                        <input
                          type="hidden"
                          v-if="appliedCouponCode != ''"
                          :value="appliedCouponCode"
                          name="appliedCouponCode"
                          id="appliedCouponCode"
                        />
                        <input
                          type="hidden"
                          v-if="isWalletApplied != 0"
                          :value="isWalletApplied"
                          name="isWalletApplied"
                          id="isWalletApplied"
                        />
                        <input
                          type="hidden"
                          v-if="
                            voucherDetails != '' &&
                            voucherDetails.voucherIdentifier != ''
                          "
                          :value="voucherDetails.voucherIdentifier"
                          name="appliedvoucherIdentifier"
                          id="appliedvoucherIdentifier"
                        />
                        <div
                          v-if="
                            orderTypeMethod != '' &&
                            PaymentTypeMethod != '' &&
                            deliveryAddressID != ''
                          "
                        >
                          <button
                            v-if="
                              (accessOrderEnum == 'schedule_pickup' ||
                                accessOrderEnum == 'schedule_delivery') &&
                              deliveryDate != '' &&
                              deliveryTime != ''
                            "
                            type="submit"
                            class="btn_1 full-width mb_5"
                            @click="validateCheckout"
                            style="
                              background: #ffc60c;
                              color: #000 !important;
                              padding: 14px 24px;
                              font-size: 16px;
                            "
                          >
                            <span
                              v-if="
                                accessPaymentEnum == 'online' &&
                                cartDetails[0].totalamount > 0
                              "
                              >Pay ₹{{ cartDetails[0].totalamount }}</span
                            ><span v-else>Place Order</span>
                          </button>
                          <button
                            v-else-if="
                              accessOrderEnum == 'deliver_now' ||
                              accessOrderEnum == 'pickup' ||
                              accessOrderEnum == 'dinenow'
                            "
                            type="submit"
                            class="btn_1 full-width mb_5"
                            @click="validateCheckout"
                            style="
                              background: #ffc60c;
                              color: #000 !important;
                              padding: 14px 24px;
                              font-size: 16px;
                            "
                          >
                            <span
                              v-if="
                                accessPaymentEnum == 'online' &&
                                cartDetails[0].totalamount > 0
                              "
                              >Pay ₹{{ cartDetails[0].totalamount }}</span
                            ><span v-else>Place Order</span>
                          </button>
                          <span
                            v-else
                            @click="validateCheckout"
                            class="btn_1 full-width mb_5"
                            style="
                              background: #ffc60c;
                              color: #000 !important;
                              padding: 14px 24px;
                              font-size: 16px;
                            "
                            >Pay ₹{{ cartDetails[0].totalamount }}</span
                          >
                        </div>
                        <div v-else>
                          <span
                            @click="validateCheckout"
                            class="btn_1 full-width mb_5"
                            style="
                              background: #ffc60c;
                              color: #000 !important;
                              padding: 14px 24px;
                              font-size: 16px;
                            "
                            >Pay ₹{{ cartDetails[0].totalamount }}</span
                          >
                        </div>
                      </form>
                    </div>

                    <!-- For Payu Gateway -->

                    <!-- For CCAvenue GateWay -->
                    <!-- <form v-if="paymentGatewayID == 2" :action="$ApiUrl+'/ccRequest'" name="payuform" method=POST> -->
                    <!-- <input type="hidden" :value="merchant_id" name="merchant_id" id="merchant_id"> -->
                    <!-- <input type="hidden" :value="tid" name="tid" id="tid">
									<input type="hidden" :value="order_id" name="order_id" id="order_id"> -->
                    <!-- <input type="hidden" :value="cartDetails[0].totalamount" name="amount" id="amount"> -->
                    <!-- <input type="hidden" value="INR" name="currency" id="currency"> -->
                    <!-- <input type="hidden" :value="$ApiUrl+'/ccResponse'" name="redirect_url" id="redirect_url">
									<input type="hidden" :value="$ApiUrl+'/ccResponse'" name="cancel_url" id="cancel_url">
									<input type="hidden" value="EN" name="language" id="language"> -->

                    <!-- <input type="hidden" :value="userID" name="userID" id="userID">
									<input type="hidden" :value="PaymentTypeMethod" name="paymentMethod" id="paymentMethod">
									<input type="hidden" :value="PaymentMode" name="PaymentMode" id="PaymentMode">
									<input type="hidden" :value="orderTypeMethod" name="orderType" id="orderType">
									<input type="hidden" :value="deliveryDate" name="deliveryDate" id="deliveryDate">
									<input type="hidden" :value="deliveryTime" name="deliveryTime" id="deliveryTime">
									<input type="hidden" value="1" name="platform" id="platform">
									<input type="hidden" :value="restaurantDetails.restBranchID" name="restBranchID" id="restBranchID">
									<input type="hidden" :value="deliveryAddressID" name="deliveryAddress" id="deliveryAddress">
									<input type="hidden" v-if="appliedCouponCode != ''" :value="appliedCouponCode" name="appliedCouponCode" id="appliedCouponCode">
									<input type="hidden" v-if="isWalletApplied != 0" :value="isWalletApplied" name="isWalletApplied" id="isWalletApplied">
									<input type="hidden" v-if="voucherDetails != '' && voucherDetails.voucherIdentifier != ''" :value="voucherDetails.voucherIdentifier" name="appliedvoucherIdentifier" id="appliedvoucherIdentifier">
									<div v-if="orderTypeMethod != '' && PaymentTypeMethod != '' && deliveryAddressID != ''">
		                            	<button v-if="(accessOrderEnum == 'schedule_pickup' || accessOrderEnum == 'schedule_delivery' ) && deliveryDate != '' && deliveryTime != ''" type="submit" class="btn_1 full-width mb_5" style="background:#FFC60C;color:#000!important;padding:14px 24px;font-size:16px;"><span v-if="accessPaymentEnum == 'online' && cartDetails[0].totalamount > 0">Pay ₹{{cartDetails[0].totalamount}}</span><span v-else>Place Order</span></button>
		                            	<button v-else-if="accessOrderEnum == 'deliver_now' || accessOrderEnum == 'pickup' || accessOrderEnum == 'dinenow'" type="submit" class="btn_1 full-width mb_5" style="background:#FFC60C;color:#000!important;padding:14px 24px;font-size:16px;"><span v-if="accessPaymentEnum == 'online' && cartDetails[0].totalamount > 0">Pay ₹{{cartDetails[0].totalamount}}</span><span v-else>Place Order</span></button>
		                            	<span v-else @click="validateCheckout" class="btn_1 full-width mb_5"  style="background:#FFC60C;color:#000!important;padding:14px 24px;font-size:16px;">Pay ₹{{cartDetails[0].totalamount}}</span>
									</div>
									<div v-else>
		                            	<span @click="validateCheckout" class="btn_1 full-width mb_5" style="background:#FFC60C;color:#000!important;padding:14px 24px;font-size:16px;">Pay ₹{{cartDetails[0].totalamount}}</span>
									</div>
									</form> -->
                    <!-- For CCAvenue GateWay -->
                  </div>

                  <!-- <div v-else class="btn_1_mobile">
									<form :action="$ApiUrl+'/initOrder'" name="payuform" method=POST>
									<input type="hidden" :value="userID" name="userID" id="userID">
									<input type="hidden" :value="PaymentTypeMethod" name="paymentMethod" id="paymentMethod">
									<input type="hidden" :value="PaymentMode" name="PaymentMode" id="PaymentMode">
									<input type="hidden" :value="orderTypeMethod" name="orderType" id="orderType">
									<input type="hidden" :value="deliveryDate" name="deliveryDate" id="deliveryDate">
									<input type="hidden" :value="deliveryTime" name="deliveryTime" id="deliveryTime">
									<input type="hidden" value="1" name="platform" id="platform">
									<input type="hidden" :value="restaurantDetails.restBranchID" name="restBranchID" id="restBranchID">
									<input type="hidden" :value="deliveryAddressID" name="deliveryAddress" id="deliveryAddress">
									<input type="hidden" v-if="appliedCouponCode != ''" :value="appliedCouponCode" name="appliedCouponCode" id="appliedCouponCode">
									<input type="hidden" v-if="isWalletApplied != 0" :value="isWalletApplied" name="isWalletApplied" id="isWalletApplied">
									<input type="hidden" v-if="voucherDetails != '' && voucherDetails.voucherIdentifier != ''" :value="voucherDetails.voucherIdentifier" name="appliedvoucherIdentifier" id="appliedvoucherIdentifier">
									<div v-if="orderTypeMethod != '' && PaymentTypeMethod != '' && deliveryAddressID != ''">
		                            	<button v-if="(accessOrderEnum == 'schedule_pickup' || accessOrderEnum == 'schedule_delivery' ) && deliveryDate != '' && deliveryTime != ''" type="submit" class="btn_1 full-width mb_5" style="background:#FFC60C;color:#000!important;padding:14px 24px;font-size:16px;"><span v-if="accessPaymentEnum == 'online' && cartDetails[0].totalamount > 0">Pay ₹{{cartDetails[0].totalamount}}</span><span v-else>Place Order</span></button>
		                            	<button v-else-if="accessOrderEnum == 'deliver_now' || accessOrderEnum == 'pickup' || accessOrderEnum == 'dinenow'" type="submit" class="btn_1 full-width mb_5" style="background:#FFC60C;color:#000!important;padding:14px 24px;font-size:16px;"><span v-if="accessPaymentEnum == 'online' && cartDetails[0].totalamount > 0">Pay ₹{{cartDetails[0].totalamount}}</span><span v-else>Place Order</span></button>
		                            	<span v-else @click="validateCheckout" class="btn_1 full-width mb_5"  style="background:#FFC60C;color:#000!important;padding:14px 24px;font-size:16px;">Pay ₹{{cartDetails[0].totalamount}}</span>
									</div>
									<div v-else>
		                            	<span @click="validateCheckout" class="btn_1 full-width mb_5" style="background:#FFC60C;color:#000!important;padding:14px 24px;font-size:16px;">Pay ₹{{cartDetails[0].totalamount}}</span>
									</div>
									</form>
		                        </div> -->
                </div>
              </div>
              <!-- /box_order -->
              <div
                class="btn_reserve_fixed mobileBasketDiv"
                :class="$isExtMob == 1 ? 'mobBasketExt' : ''"
                v-if="cartDetails.length > 0"
              >
                <!-- <div class="mobileBasketDiv mobileBasketAlgin" v-if="cartDetails.length > 0"> -->
                <div class="row">
                  <div class="col-6 cartItemsCharges">
                    <p class="cartItemsMob">
                      {{ cartItemsDetails.length }} item in a cart | ₹{{
                        cartDetails[0].totalamount
                      }}
                    </p>
                    <p class="deliveryType">
                      <img
                        v-if="accessOrderEnum != ''"
                        style="margin-right: 5px"
                        src="../../templateAssests/img/ckgallery/check.png"
                        height="18"
                        width="18"
                      />
                      <span v-if="accessOrderEnum === 'pickup'">Pick Up</span>
                      <span v-if="accessOrderEnum === 'dinenow'">Dine Now</span>
                      <span
                        v-if="
                          accessOrderEnum === 'schedule_pickup' ||
                          accessOrderEnum === 'schedule_delivery'
                        "
                        >Schedule</span
                      >
                      <span
                        v-if="
                          accessOrderEnum === 'subscription_pickup' ||
                          accessOrderEnum === 'subscription_delivery'
                        "
                        >Subscribe</span
                      >
                      <span v-if="accessOrderEnum === 'deliver_now'"
                        >Deliver Now</span
                      >
                    </p>
                  </div>
                  <div class="col-6 align-center viewCartItems">
                    <div
                      v-if="
                        (userMobileNo == null || userMobileNo == '') &&
                        orderTypeMethod != '' &&
                        PaymentTypeMethod != '' &&
                        deliveryAddressID != ''
                      "
                      class="btn_1_mobile"
                    >
                      <button @click="getCheckOutAccess" class="btn">
                        Add Number
                      </button>
                    </div>
                    <div v-else>
                      <!-- <form :action="$ApiUrl+'/initOrder'" name="payuform" method=POST> -->
                      <!-- <form method="post" @submit="makePament()" :action="$ApiUrl+'/initOrder'"> -->

                      <!-- <div v-if="restaurantDetails.restBranchID == 23"> -->
                    <div v-if="ccAvenueRestArr && ccAvenueRestArr.includes(restaurantDetails.restBranchID)">
                        <form
                          method="post"
                          @submit="makePament()"
                          :action="
                            paymentGatewayID == 'gpay'
                              ? $ApiUrl + '/ccRequest'
                              : paymentGatewayID == 'phonepe'
                              ? $ApiUrl + '/ccRequest'
                              : paymentGatewayID == 'paytm'
                              ? $ApiUrl + '/initOrder'
                              : paymentGatewayID == 'creditcard'
                              ? $ApiUrl + '/initOrder'
                              : paymentGatewayID == 'debitcard'
                              ? $ApiUrl + '/initOrder'
                              : paymentGatewayID == 'others'
                              ? $ApiUrl + '/initOrder'
                              : $ApiUrl + '/initOrder'
                          "
                        >
                          <!-- <form action="http://3.6.19.77/api/initOrder" name="payuform" method=POST> -->
                          <!-- <form action="https://www.codetreasure.com/ck_API/public/api/initOrder" name="payuform" method=POST> -->
                          <!-- <form action="/initOrder" name="payuform" method=POST> -->
                          <input
                            type="hidden"
                            :value="userID"
                            name="userID"
                            id="userID"
                          />
                          <input
                            type="hidden"
                            :value="PaymentTypeMethod"
                            name="paymentMethod"
                            id="paymentMethod"
                          />
                          <!-- <div v-if="getOnlineMethod == paytm"> -->
                          <input
                            type="hidden"
                            :value="this.getOnlinePaymentMethod"
                            name="pg"
                            id="pg"
                          />
                          <input
                            type="hidden"
                            :value="this.getbankCode"
                            name="bankcode"
                            id="bankcode"
                          />
                          <!-- </div>
                          <div v-else> -->
                          <input
                            type="hidden"
                            :value="this.getOnlinePaymentMethod"
                            name="enforce_paymode"
                            id="enforce_paymode"
                          />
                          <!-- </div> -->
                          <input
                            type="hidden"
                            :value="PaymentMode"
                            name="PaymentMode"
                            id="PaymentMode"
                          />
                          <input
                            type="hidden"
                            :value="orderTypeMethod"
                            name="orderType"
                            id="orderType"
                          />
                          <input
                            type="hidden"
                            :value="deliveryDate"
                            name="deliveryDate"
                            id="deliveryDate"
                          />
                          <input
                            type="hidden"
                            :value="deliveryTime"
                            name="deliveryTime"
                            id="deliveryTime"
                          />
                          <input
                            type="hidden"
                            value="1"
                            name="platform"
                            id="platform"
                          />
                          <input
                            type="hidden"
                            :value="restaurantDetails.restBranchID"
                            name="restBranchID"
                            id="restBranchID"
                          />
                          <input
                            type="hidden"
                            :value="deliveryAddressID"
                            name="deliveryAddress"
                            id="deliveryAddress"
                          />
                          <input
                            type="hidden"
                            v-if="appliedCouponCode != ''"
                            :value="appliedCouponCode"
                            name="appliedCouponCode"
                            id="appliedCouponCode"
                          />
                          <input
                            type="hidden"
                            v-if="isWalletApplied != 0"
                            :value="isWalletApplied"
                            name="isWalletApplied"
                            id="isWalletApplied"
                          />
                          <input
                            type="hidden"
                            v-if="
                              voucherDetails != '' &&
                              voucherDetails.voucherIdentifier != ''
                            "
                            :value="voucherDetails.voucherIdentifier"
                            name="appliedvoucherIdentifier"
                            id="appliedvoucherIdentifier"
                          />
                          <div
                            v-if="
                              orderTypeMethod != '' &&
                              PaymentTypeMethod != '' &&
                              deliveryAddressID != ''
                            "
                          >
                            <button
                              v-if="
                                (accessOrderEnum == 'schedule_pickup' ||
                                  accessOrderEnum == 'schedule_delivery') &&
                                deliveryDate != '' &&
                                deliveryTime != ''
                              "
                              type="submit"
                              class="btn btnCheckoutMobile"
                            >
                              <img
                                src="../../templateAssests/img/ckgallery/loader.gif"
                                height="25"
                                width="25"
                                v-if="showLoader == 1"
                              /><span v-else>Make Payment</span>
                            </button>
                            <button
                              v-else-if="
                                accessOrderEnum == 'deliver_now' ||
                                accessOrderEnum == 'pickup' ||
                                accessOrderEnum == 'dinenow'
                              "
                              type="submit"
                              class="btn btnCheckoutMobile"
                            >
                              <img
                                src="../../templateAssests/img/ckgallery/loader.gif"
                                height="25"
                                width="25"
                                v-if="showLoader == 1"
                              /><span v-else>Make Payment</span>
                            </button>
                            <button
                              v-else
                              :disabled="isDisabled"
                              class="btn"
                              @click="validateCheckout"
                            >
                              Make Payment
                            </button>
                            <!-- <button type="submit" class="btn">Make Payment</button> -->
                          </div>
                          <div v-else>
                            <button
                              :disabled="isDisabled"
                              class="btn"
                              @click="validateCheckout"
                            >
                              Make Payment
                            </button>
                          </div>
                        </form>
                      </div>
                      <div v-else>
                      <form
                        method="post"
                        @submit="makePament()"
                        :action="
                          paymentGatewayID == 'gpay'
                            ? $ApiUrl + '/initOrder'
                            : paymentGatewayID == 'phonepe'
                            ? $ApiUrl + '/initOrder'
                            : paymentGatewayID == 'paytm'
                            ? $ApiUrl + '/initOrder'
                            : paymentGatewayID == 'creditcard'
                            ? $ApiUrl + '/initOrder'
                            : paymentGatewayID == 'debitcard'
                            ? $ApiUrl + '/initOrder'
                            : paymentGatewayID == 'others'
                            ? $ApiUrl + '/initOrder'
                            : $ApiUrl + '/initOrder'
                        "
                      >
                        <!-- <form action="http://3.6.19.77/api/initOrder" name="payuform" method=POST> -->
                        <!-- <form action="https://www.codetreasure.com/ck_API/public/api/initOrder" name="payuform" method=POST> -->
                        <!-- <form action="/initOrder" name="payuform" method=POST> -->
                        <input
                          type="hidden"
                          :value="userID"
                          name="userID"
                          id="userID"
                        />
                        <input
                          type="hidden"
                          :value="PaymentTypeMethod"
                          name="paymentMethod"
                          id="paymentMethod"
                        />
                        <!-- <div v-if="getOnlineMethod == paytm"> -->
                        <input
                          type="hidden"
                          :value="this.getOnlinePaymentMethod"
                          name="pg"
                          id="pg"
                        />
                        <input
                          type="hidden"
                          :value="this.getbankCode"
                          name="bankcode"
                          id="bankcode"
                        />
                        <!-- </div>
                        <div v-else> -->
                        <input
                          type="hidden"
                          :value="this.getOnlinePaymentMethod"
                          name="enforce_paymode"
                          id="enforce_paymode"
                        />
                        <!-- </div> -->
                        <input
                          type="hidden"
                          :value="PaymentMode"
                          name="PaymentMode"
                          id="PaymentMode"
                        />
                        <input
                          type="hidden"
                          :value="orderTypeMethod"
                          name="orderType"
                          id="orderType"
                        />
                        <input
                          type="hidden"
                          :value="deliveryDate"
                          name="deliveryDate"
                          id="deliveryDate"
                        />
                        <input
                          type="hidden"
                          :value="deliveryTime"
                          name="deliveryTime"
                          id="deliveryTime"
                        />
                        <input
                          type="hidden"
                          value="1"
                          name="platform"
                          id="platform"
                        />
                        <input
                          type="hidden"
                          :value="restaurantDetails.restBranchID"
                          name="restBranchID"
                          id="restBranchID"
                        />
                        <input
                          type="hidden"
                          :value="deliveryAddressID"
                          name="deliveryAddress"
                          id="deliveryAddress"
                        />
                        <input
                          type="hidden"
                          v-if="appliedCouponCode != ''"
                          :value="appliedCouponCode"
                          name="appliedCouponCode"
                          id="appliedCouponCode"
                        />
                        <input
                          type="hidden"
                          v-if="isWalletApplied != 0"
                          :value="isWalletApplied"
                          name="isWalletApplied"
                          id="isWalletApplied"
                        />
                        <input
                          type="hidden"
                          v-if="
                            voucherDetails != '' &&
                            voucherDetails.voucherIdentifier != ''
                          "
                          :value="voucherDetails.voucherIdentifier"
                          name="appliedvoucherIdentifier"
                          id="appliedvoucherIdentifier"
                        />
                        <div
                          v-if="
                            orderTypeMethod != '' &&
                            PaymentTypeMethod != '' &&
                            deliveryAddressID != ''
                          "
                        >
                          <button
                            v-if="
                              (accessOrderEnum == 'schedule_pickup' ||
                                accessOrderEnum == 'schedule_delivery') &&
                              deliveryDate != '' &&
                              deliveryTime != ''
                            "
                            type="submit"
                            class="btn btnCheckoutMobile"
                          >
                            <img
                              src="../../templateAssests/img/ckgallery/loader.gif"
                              height="25"
                              width="25"
                              v-if="showLoader == 1"
                            /><span v-else>Make Payment</span>
                          </button>
                          <button
                            v-else-if="
                              accessOrderEnum == 'deliver_now' ||
                              accessOrderEnum == 'pickup' ||
                              accessOrderEnum == 'dinenow'
                            "
                            type="submit"
                            class="btn btnCheckoutMobile"
                          >
                            <img
                              src="../../templateAssests/img/ckgallery/loader.gif"
                              height="25"
                              width="25"
                              v-if="showLoader == 1"
                            /><span v-else>Make Payment</span>
                          </button>
                          <button
                            v-else
                            :disabled="isDisabled"
                            class="btn"
                            @click="validateCheckout"
                          >
                            Make Payment
                          </button>
                          <!-- <button type="submit" class="btn">Make Payment</button> -->
                        </div>
                        <div v-else>
                          <button
                            :disabled="isDisabled"
                            class="btn"
                            @click="validateCheckout"
                          >
                            Make Payment
                          </button>
                        </div>
                      </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
		        <!-- /row -->
		    </div>
		    <!-- /container -->
		</div>
		<!-- /bg_gray -->

		<!-- /container -->

	</main>
	<!-- /main -->

<footerLayout />
	<!-- footer layout -->

	<b-modal id="modal-coupon"  modal-class="applyCouponModal" hide-header  hide-footer size="md" centered>
        <div class="container deliveryAddContainer" v-if="couponsDetailsArr.length <= 0">
            <div class="clearfix addDeliveryHeader">
                <h4>Apply Coupon</h4>
            </div>
            <!-- / clearfix -->
		</div>
		<!-- /Container -->
        <div class="availOffersSection" v-if="couponsDetailsArr.length <= 0">
            <div class="container">
                    <div class="couponsSectionPop" style="display: flex;flex-wrap: wrap;min-height: 100%;align-content: center;">
                        <div class="mainContent align-center">
                            <div>
                                <h4 class="descTitle">No Data Available</h4>
                            </div>
                        </div>
                    </div>
                    <!-- / couponsSectionPop -->
                </div>
        </div>
        <div class="container deliveryAddContainer" v-if="couponsDetailsArr.length > 0">
            <div class="clearfix addDeliveryHeader">
                <h4>Apply Coupon</h4>
            </div>
            <!-- / clearfix -->
		</div>
		<!-- /Container -->
        <div class="availOffersSection" v-if="couponsDetailsArr.length > 0">
            <div class="availOffers">
                <div class="container availOffersDiv">
                    <h6 class="availOffHead">Available Coupons</h6>
                </div>
            </div>
            <!-- / availOffers  -->

                <div class="container">
                    <div class="couponsSectionPop" v-for="coupons in couponsDetailsArr" v-bind:key="coupons">
                        <div class="row couponRow">
                            <div class="col-6 col-md-4 col-lg-4 col-xl-4 couponDiv align-center">
                                <span class="couponCode">{{coupons.couponCode}}</span>
                            </div>
                            <div class="col-6 col-md-8 col-lg-8 col-xl-8 applyDiv align-right">
                                <a @click="applyCoupon(coupons.couponCode)" v-if="cartDetails[0].totalitemprice >= coupons.minOrderValue && (coupons.validityTimeStart <= time && coupons.validityTimeEnd >= time)" class="customAnch">Apply</a>
                                <span class="noApply" v-else>Not Applicable</span>
                            </div>
                        </div>
                        <!-- / row -->
                        <div class="mainContent">
                            <div>
                                <h4 class="descTitle">{{coupons.couponTitle}}</h4>
                                <p class="descDescription">{{coupons.couponDescription}}</p>
                            </div>
                        </div>

                        <hr class="hrCoupon">
                        
                    </div>
                    <!-- / couponsSectionPop -->
                </div>
        </div>
  </b-modal> 

  <b-modal id="modal-voucher"  modal-class="applyVoucherModal" hide-header  hide-footer size="lg" centered>
        <div class="container deliveryAddContainer" v-if="vouchersDetailsArr.length <= 0">
            <div class="clearfix addDeliveryHeader">
                <h4>Apply Voucher</h4>
            </div>
            <!-- / clearfix -->
		</div>
		<!-- /Container -->
        <div class="availOffersSection" v-if="vouchersDetailsArr.length <= 0">
            <div class="container">
                    <div class="couponsSectionPop" style="display: flex;flex-wrap: wrap;min-height: 100%;align-content: center;">
                        <div class="mainContent align-center">
                            <div>
                                <h4 class="descTitle">No Data Available</h4>
                            </div>
                        </div>
                    </div>
                    <!-- / couponsSectionPop -->
                </div>
        </div>
        <div class="container deliveryAddContainer" v-if="vouchersDetailsArr.length > 0">
            <div class="clearfix addDeliveryHeader">
                <h4>Apply Voucher</h4>
            </div>
            <!-- / clearfix -->
		</div>
		<!-- /Container -->
        <div class="availOffersSection" v-if="vouchersDetailsArr.length > 0">
            <div class="availOffers">
                <div class="container availOffersDiv">
                    <h6 class="availOffHead">Available Vouchers</h6>
                </div>
            </div>
            <!-- / availOffers  -->
				<div class="row">
					<div class="preBuyCard col-6" v-for="voucher in vouchersDetailsArr" v-bind:key="voucher">
						<div class="preBuyTemplate templateType1" v-if="voucher.templateType == 1">
							<div class="preBuyImage" :style="{background : voucher.templateColor}">
								<div class="Voucheropacity-mask">
									<div class="voucherDetails">
										<!-- <span class="voucherStatus" :style="{color : voucher.voucherStatusColor}">{{voucher.voucherStatus}}</span> -->
										<img class="preBuyImageTempalet1" :src="voucher.templateImage == null || voucher.templateImage == ''? 'img' : voucher.templateImage" height="166">
										<h5 class="preBuyCouponTitle">#{{voucher.voucherIdentifier}}</h5>
										<p class="PreCouponDesc one-line-text">{{voucher.restaurantName}}</p>
										<p class="PreCouponDesc one-line-text">{{voucher.dishTitle != '' ? voucher.dishTitle+ ' |' : ''}} ₹{{voucher.voucherAmt}} </p>
										<p class="PreCouponDesc one-line-text"> Expire on: {{voucher.expiryDate}}</p>
										<a class="viewRestBtnAnch" @click="applyVoucher(voucher.voucherIdentifier)" v-if="voucher.isRedeemable == 1">
											<button class="btn_1 viewRestBtn view_details">Redeem</button>
										</a>
										<a class="viewRestBtnAnch" v-else>
											<button class="btn_1 disabledBtn view_details">Not Applicable</button>
										</a>
									</div>
								</div>
							</div>
						</div>
						<!-- /template 1 -->

						<div class="preBuyTemplate templateType2" v-if="voucher.templateType == 2">
							<div class="preBuyImage" :style="{ backgroundImage: 'url(' + voucher.templateImage + ')' }">
								<div class="Voucheropacity-mask">
									<div class="voucherDetails">
										<span class="voucherStatus" :style="{color : voucher.voucherStatusColor}">{{voucher.voucherStatus}}</span>
										<h5 class="preBuyCouponTitle">#{{voucher.voucherIdentifier}}</h5>
										<p class="PreCouponDesc one-line-text">{{voucher.restaurantName}}</p>
										<p class="PreCouponDesc one-line-text">{{voucher.dishTitle != '' ? voucher.dishTitle+ ' |' : ''}} ₹{{voucher.voucherAmt}} </p>
										<p class="PreCouponDesc one-line-text"> Expire on: {{voucher.expiryDate}}</p>
										<a class="viewRestBtnAnch" @click="applyVoucher(voucher.voucherIdentifier)" v-if="voucher.isRedeemable == 1">
											<button class="btn_1 viewRestBtn view_details">Redeem</button>
										</a>
										<a class="viewRestBtnAnch" v-else>
											<button class="btn_1 disabledBtn view_details">Not Applicable</button>
										</a>
									</div>
								</div>
							</div>
						</div>

						<!-- /template 2 -->

						<div class="preBuyTemplate templateType3" v-if="voucher.templateType == 3">
							<div class="preBuyImage" :style="{ backgroundImage: 'url(' + voucher.templateImage + ')' }">
								<div class="Voucheropacity-mask">
									<div class="voucherDetails">
										<span class="voucherStatus" :style="{color : voucher.voucherStatusColor}">{{voucher.voucherStatus}}</span>
										<h5 class="preBuyCouponTitle">#{{voucher.voucherIdentifier}}</h5>
										<p class="PreCouponDesc one-line-text">{{voucher.restaurantName}}</p>
										<p class="PreCouponDesc one-line-text">{{voucher.dishTitle != '' ? voucher.dishTitle+ ' |' : ''}} ₹{{voucher.voucherAmt}} </p>
										<p class="PreCouponDesc one-line-text"> Expire on: {{voucher.expiryDate}}</p>
										<a class="viewRestBtnAnch" @click="applyVoucher(voucher.voucherIdentifier)" v-if="voucher.isRedeemable == 1">
											<button class="btn_1 viewRestBtn view_details">Redeem</button>
										</a>
										<a class="viewRestBtnAnch" v-else>
											<button class="btn_1 disabledBtn view_details">Not Applicable</button>
										</a>
									</div>
								</div>
							</div>
						</div>
						<!-- /template 3 -->
					</div>
				</div>

                <!-- <div class="container">
                    <div class="couponsSectionPop" v-for="voucher in vouchersDetailsArr" v-bind:key="voucher">
                        <div class="row couponRow">
                            <div class="col-md-4 col-lg-4 col-xl-4 couponDiv align-center">
                                <span class="couponCode">{{voucher.voucherIdentifier}}</span>
                            </div>
                            <div class="col-md-8 col-lg-8 col-xl-8 applyDiv align-right">
                                <a @click="applyVoucher(voucher.voucherIdentifier)" v-if="voucher.isRedeemable == 1" class="customAnch">Redeem</a>
                                <span class="noApply" v-else>Not Applicable</span>
                            </div>
                        </div> -->
                        <!-- / row -->
                        <!-- <div class="mainContent">
                            <div>
                                <h4 class="descTitle">{{voucher.voucherTitle}}</h4>
                                <p class="descDescription">Redeemable amount ₹{{voucher.amount}}</p>
                            </div>
                        </div>

                        <hr class="hrCoupon">
                        
                    </div> -->
                    <!-- / couponsSectionPop -->
                <!-- </div> -->
        </div>
  </b-modal> 

  <b-modal id="modal-cuisinePopup" scrollable  :footer-bg-variant="footerBgVariant" v-if="cusinieAddonsDetails && cusineAddonModalShow === true" hide-header  size="md" centered>
		<div class="container" style="max-width:100%!important;padding:0px;">
		
		<div>
            <img :style="cusinieAddonsDetails.cuisineDetail.image == null || cusinieAddonsDetails.cuisineDetail.image == '' ? 'display:none;' : 'display:block;'" :src="cusinieAddonsDetails.cuisineDetail.image == null || cusinieAddonsDetails.cuisineDetail.image == ''? 'img' : cusinieAddonsDetails.cuisineDetail.image" onerror="this.src='https://assets.cloudkitch.co.in/user_documents/Placeholder.png';" width="150" style="width:150px; max-height:200px; padding: 0px 15px 10px 0;float: left;margin-right: 10px;" alt="thumb" class="lazy cuisineModalImg">
            <h5>{{cusinieAddonsDetails.cuisineDetail.name}}</h5>
            <p style="color:#F68F30;margin-bottom:5px;">{{cusinieAddonsDetails.cuisineDetail.restaurantName}}</p>
            <p class="menuPrice">₹{{cusinieAddonsDetails.cuisineDetail.price}} | Serves {{cusinieAddonsDetails.cuisineDetail.serves}}</p>
            <div class="foodTypeModal align-center" :style="{background : mealType.background}" v-for="mealType in cusinieAddonsDetails.cuisineDetail.type" v-bind:key="mealType.name">
                {{mealType.name}}
            </div>
            <p style="margin-bottom:5px;">{{cusinieAddonsDetails.cuisineDetail.description}}</p>
			
		</div>
		
		<br>
			<div class="row">
				<div class="customizeSection col-6 col-lg-6 col-xl-6" style="float:left;">
					<p v-if="cusinieAddonsDetails.cuisineDetail.isCustomisable=='1'" style="color: #a38379;margin-bottom:0px; padding-top: 8px;">Customize</p>
				</div>
				<div class="socialBtns align-right col-6 col-lg-6 col-xl-6">
					<div class="socialBtnsDiv align-center" style="background:#4C6EF5;">
						<img src="../../templateAssests/img/ckgallery/fb.png" height="15" width="10" alt="fb">
					</div>
					<div class="socialBtnsDiv align-center" style="background:#00E676;">
						<img src="../../templateAssests/img/ckgallery/whatsapp.png" height="15" width="14" alt="whatsapp">
					</div>
					<div class="socialBtnsDiv align-center" style="background:#A4827D;">
						<img src="../../templateAssests/img/ckgallery/share.png" height="15" width="15" alt="share">
					</div>
				</div>
			</div>
			<hr v-if="cusinieAddonsDetails.cuisineDetail.isCustomisable=='1'" style="opacity:1;margin: 8px 0 12px 0;">
		<!-- / customizeSection -->

		<!--  Dropdown -->
		<vsa-list class="sauceSection" v-if="cusinieAddonsDetails.cuisineAddons != undefined && cusinieAddonsDetails.cuisineDetail.isCustomisable=='1'">
			<div  v-for="(addonItemsArr, groupIndex) in cusinieAddonsDetails.cuisineAddons" v-bind:key="addonItemsArr.addonGroupID">
    		 <vsa-item :forceActive="groupIndex == 0 ? true : false">
				<vsa-heading>
					{{addonItemsArr.groupName}} <span v-if="addonItemsArr.required == 0">(Optional)</span>
				</vsa-heading>
	
				<vsa-content>
					<div class="row" v-for="addOnItems in addonItemsArr.data" v-bind:key="addOnItems.addOnItemID">
						
						<div class="col-md-12">
					 <b-form-group v-slot="{ ariaDescribedby }">
						<b-form-checkbox-group
							id="checkbox-group-2"
							v-model="selectedAddonCartItems"
							:aria-describedby="ariaDescribedby"
							name="addOnItems" @change="selectCartModalAddons(cusinieAddonsDetails.cuisineDetail.price)">
							<b-form-checkbox checked="true" :value="addOnItems">{{addOnItems.title}} (+ ₹{{addOnItems.price}})</b-form-checkbox>
						</b-form-checkbox-group>
					 </b-form-group>
						</div>
					</div>
				</vsa-content>
			</vsa-item>
			</div>
  </vsa-list>
		<!-- / Dropdown -->
		<!-- endiIF -->
	</div>
		<!-- /Container -->
		<template #modal-footer>
        <div class="w-100">
          <p class="float-left">Item total ₹{{itemTotalCartModal}}</p>
		  <div class="row float-right" v-if="cusinieAddonsDetails.isInStock != 0">
			  <div class="col-6 align-right">
				 <integer-plusminus v-on:click.native="updateCartDataFromModal(cusinieAddonsDetails.cuisineDetail)" v-model="itemFromCartModal" :min="0" :max="100" :step="1">
				 <!-- <integer-plusminus v-model="itemFromCartModal" :min="0" :max="100" :step="1"> -->
				 </integer-plusminus>
				</div>
				<div class="col-6">
				<b-button
					size="sm"
					class="float-right viewDish-footer-btn"
					v-on:click="updateItemAddonModal(cusinieAddonsDetails.cuisineDetail, 1)"
				>
					Update Cart
				</b-button>
				</div>
		  </div> 
        </div>
      </template>
  </b-modal> 
    </div>
	<!-- /root div -->
</template>
    
    <!-- Add "scoped" attribute to limit CSS to this component only -->
    <!-- SPECIFIC CSS -->
<style scoped src="../../templateAssests/css/listing.css"></style>
    <style scoped src="../../templateAssests/css/detail-page.css"></style>
<style scoped>
        .align-right
        {
            text-align: right;
        }
        .align-left
        {
            text-align: left;
        }
		.customAnch{
			color:#F3766A!important;
		}
		/* .vsa-list {
			--vsa-heading-padding: 0 0;
			--vsa-highlight-color: transparent; 
			--vsa-bg-color: transparent;
			--vsa-border-color: transparent;
			--vsa-default-icon-size: 0.5;
		}
		.vsa-item__trigger__content{
			font-size:16px;
		} */
		/* .addDeliveryModal > .modal-dialog > .modal-content > .modal-body {
			padding:30px!important;
		} */
        .review_card{
            padding:25px 25px 25px 25px;
        }
        .deliveryCard{
            border:1px solid #ededed;
            border-radius:3px;
            padding: 20px;
        }
        .addressType h5{
            font-size:16px;
        }
        .address span{
            font-size:14px;
        }
        .deliverBtn{
            margin-top:17px;
        }
        .btn_1{
            background:#A4827D;
        }
        .disabledBtn{
            background:rgba(52, 58, 64, 0.25) !important;
			cursor: not-allowed;
			color: #212121!important;
			
        }
        .btnNewAdd{
            background: #F3766A;
        }
        .btnSave{
                width: 85px;
                height: 35px;
        }
        .paymentTypeDiv{
            margin-top:20px;
        }
		.otherAdd{
            margin-top:20px;
		}
		.head{
			position: relative;
			top: 14px;
			border-radius: 0!important;
			background-color: #fef1f0!important;
    		color: #000!important;
			text-align: left!important;
			padding :17px 10px 12px 20px !important;
		}
		.locationDetails p{
			margin-bottom:0;
			margin-top: 1rem;
			color:#a6a1a1;
		}
		.dishServings img{
			margin-right: 5px;
		}
		/* .dishDetails{
			border-bottom:1px solid #ededed;
		} */
		.couponsSection{
			border:1px solid #f5f5f5;
			/* padding :14px 15px 14px; */
			/* padding :11px 9px; */
			/* padding :11px 4px; */
			padding :11px 13px;
			border-radius: 5px;
			max-width: 125px;
			margin-right: auto;
		}
		.rewardsSectiondiv
		{
			padding :8px 10px;
		}
		.mdi-ticket-percent-outline{
			font-size:20px;
		}
		.rewardHeading{
			font-size: 14px;
			margin: 5px 0;
			color: #212121;
		}
		.rewardDesc
		{
			color: #777777;
			font-size: 10px;
			margin: 0 0;
		}
		.walletSection{
			border:1px solid #ededed;
			padding :14px 15px 14px;
		}
		.mdi-wallet-outline{
			font-size:20px;
		}
		.walletText{
			font-size: 14px;
			margin-left: 10px;
		}
		.walletSection a{
			position: absolute;
    		right: 15px;
		}
		.opt_order {
			border-bottom: transparent;
			margin-bottom: 0px;
			margin: 0px;
		}
		.dishName p{
			font-size:18px;
		}
		.cardTitle{
			font-size: 24px;
		}
		#reviews{
			box-shadow:  0px 0px 15px rgba(0, 0, 0, 0.05)!important;
		}
		.expandHead{
			color:#000;
			font-size: 13px!important;
		}
		.expandBody{
			margin-left: 31px;
			color: #626262;
			font-size: 12px;
		}
		.deliveryAddContainer{
			margin-left: 8px!important;
			margin-right: 8px!important;
		}
		/* .addDeliveryHeader{
			margin-top: 31px;
			margin-bottom: 27px;
		}
		.InfoText label{
			color:#777;
			font-size: 14px;
			margin: 0px 0px 10px;
		}
		.deliveryLocat select, .completeAdd input, .landmark input{
			margin-bottom:21px;
		}
		.addTypeRadio label{
			margin: 0px 0px 19px;
		} */
		.header_in{
			position: fixed;
			z-index: 10;
		}
		.wave {
			z-index: 10;
		}
		main{
			margin-top: 68px;
		}
		.titleHead{
			/* margin-bottom: 21.5px; */
			margin-top: 8px;
		}
		.optionHeading{
			margin-top: 19.5px;
			color: #212121;
			font-size: 15px;
		}
		.optionContentText{
			color: #424242;
			font-size: 13px;
		}
		.list_menu section{
			padding-top: 0px!important;
		}
		.review_card .reply{
			padding-top: 0px!important;
		}
		.rewardsSection{
			margin-top: 8px;
    		margin-bottom: 8px;
		}
		/* .rewardsSection .rewardsSectiondiv{
			margin-right: 5px;
		} */
		.cartItemsCharges
		{
			display: flex;
			flex-wrap: wrap;
			min-height: 100%;
			align-content: center;
		}
		.deliveryType
		{
			margin-bottom: 0;
		}
		.btnCheckoutMobile{
			background: #FFC60C!important;
			color: #212121!important;
		}
		@media only screen and (max-width: 971px) {
			.box_order.mobile_fixed
			{
				display: block;
				position: inherit;
				z-index: 5;
			}
			/* .box_order.mobile_fixed .head{
				display: none;
			} */
			.box_order.mobile_fixed .btn_1_mobile
			{
				display: none;
				position: inherit;
			}
		}
		.mobileBasketAlgin
		{
			margin-top: 20px;
		}
		.mobileBasketDiv{
			background-color:#F3766A!important;
		}
		.cartItemsMob{
			margin-bottom: 2px;
		}
		 @media (min-width: 1200px){
      .deliveryAddContainer{
        max-width : 95%!important;
      }
    }
	.availOffers
{
    background: #f3f3f3;
}
.availOffersDiv{
    padding: 10px 0;
    margin-bottom: 10px;
}
.availOffHead
{
    margin-bottom: 0px;
}
.couponRow
{
    margin: 0;
}
.couponDiv{
    background: #fef1f0;
    /* padding: 10px 0; */
    border: 2px dashed #F3766A;
    border-radius: 5px;
    padding: 4px 6px;
    width: fit-content;
    height: fit-content;
}
.couponCode{
    font-size: 14px;
}
.applyDiv{
    line-height: 3;
    color: #F3766A;
    padding-right:0px;
    margin-left: auto;
}
/* .mainContent{
    margin-top: 15px;
} */
.descTitle
{
    font-size: 14px;
    margin-bottom: 3px;
}
.descDescription
{
    font-size: 12px;
    color: #777;
    margin-bottom: 3px;
}
.availOffersSection
{
    overflow-y: scroll;
    max-height: 430px;
}
.availOffersSection::-webkit-scrollbar 
{
    display: none;
}
.hrCoupon
{
    margin: 11px 0;
}
.noApply
{
    color: #777;
}
.preBuyCard
{
	margin-bottom: 10px;
}
.viewRestBtn
{
	background: #FFC60C;
}
.viewRestBtnAnch
{
	position: absolute;
	bottom: 18px;
}
.preBuyImage
	{
		object-fit:cover;
		min-width: 100%;
        /* min-height: 160px; */
        min-height: 190px;
		border-radius:8px;
        background-position: 50%;
        background-repeat: no-repeat;
        /* border-radius: 5px; */
        overflow: hidden;
        background-size: cover;
	}
    .Voucheropacity-mask
    {
        width: 95%;
        height: 100%;
        position: absolute;
        z-index: 2;
		border-radius:8px;
        background-color: rgba(0,0,0,.2);
    }
    .templateType3 .preBuyImage .Voucheropacity-mask
    {
        background-color: rgba(0,0,0,.4)!important;
    }
    .voucherDetails
    {
        padding: 20px 16px;
        height: 100%;
    }
	.preBuyCouponTitle
	{
		font-family: 'GothamMedium';
		color: #fff;
		font-size: 17px;
	}
	.PreCouponDesc
	{
		color: #fff;
		font-size: 14px;
        display: -webkit-box;
		margin-bottom: 10px;
	}
	.preBuyImageTempalet1
	{
		height: 65px;
		object-fit: contain;
		position: absolute;
		right: 22px;
		bottom: 30px;
        /* float: right;
        position: relative;
        top: 65px;
        right: 0; */
	}
    .preBuyImage, .inImage
    {
        max-width: 320px!important;
        max-height: 190px!important;
    }
    .voucherStatus
    {
        position: absolute;
        right: 22px;
    }
	.vsa-list 
{
    --vsa-heading-padding: 0 0;
    --vsa-highlight-color: transparent; 
    --vsa-bg-color: transparent;
    --vsa-border-color: #eaeaea;
    --vsa-default-icon-size: 0.3;
}
.vsa-item__trigger{
    padding: 10px!important;
}
</style>
<style>
.swal2-confirm{
	width: 124px!important;
}
.pac-container {
    background-color: #FFF;
    z-index: 20;
    position: fixed;
    display: inline-block;
    float: left;
}
.modal{
    z-index: 20;   
}
.modal-backdrop{
    z-index: 10;        
}
.alertMsg{
  position: fixed;
    right: 0px;
    z-index: 500;
    top: 95px;
    width: 22%;
}
@media (max-width:971px)
{
	.alertMsg
	{
    	width: 50%;
	}
}
.cartIconHeader
	{
		display: none!important;
	}
	.wave.footer{
		z-index: 5;
	}
	#modal-coupon___BV_modal_body_
{
    padding:  1rem 0;
}
.vsa-item__trigger__content
{
    font-size:14px;
    color:#777;
}
.onlinePayButton {
  height: 110px;
  vertical-align: middle;
  display: table-cell;
  width: 150px;
}
@media only screen and (max-width: 600px) {
  .onlinePayCardMobile {
    width: 50% !important;
  }
}
</style>