<script>
import Vue from 'vue'

import VueGoogleAutocomplete from 'vue-google-autocomplete'

export default {
	props:[
		'userAddressData'
	],

    data() {
        return {
            variants: ['primary', 'secondary', 'success', 'warning', 'danger', 'info', 'light', 'dark', 'white'],
        // headerBgVariant: 'dark',
        // headerTextVariant: 'light',
        // bodyBgVariant: 'light',
        // bodyTextVariant: 'dark',
        footerBgVariant: 'white',
        footerTextVariant: 'dark',
		alertText:'',
		alertVariants:'',
		alertHead:'',
		alertShow: false,
		addressTypeRadio:0,
		loader:0,
        }
    },

    components: {
                    VueGoogleAutocomplete,
                },
    methods : {
                /**
		 * When the location found
		 * @param {Object} addressData Data of the found location
		 * @param {Object} placeResultData PlaceResult object
		 * @param {String} id Input container ID
		 */
		getAddressData: function (addressData, placeResultData) {
			this.address = addressData;
			if(placeResultData){
				this.placesApiresult = placeResultData;
				this.addressInput = placeResultData.formatted_address;
				this.latitude = placeResultData.geometry.location.lat();
				this.longitude = placeResultData.geometry.location.lng();  
			}
			//   console.log(placeResultData);
			// console.log(this.latitude);
			// console.log(this.longitude);
		},

		// getAddressTypeUserData(userAddressDataObj)
		// {
		// 	if (userAddressDataObj.userAddID > 0) {
		// 		return this.addressTypeRadio = userAddressDataObj.type;
		// 	}
		// 	else
		// 	{
		// 		return this.addressTypeRadio = 1;
		// 	}
		// },
		getAddressType(radioValue)
		{
			return this.addressTypeRadio = radioValue;
			// console.log(this.addressTypeRadio);
		},

		saveAddress()
		{
			var completeAddress = this.$refs.completeAddress.value;
			var landmark = this.$refs.landmark.value;
			var addressTypeRadio = this.addressTypeRadio;
			if (this.userAddressData.userAddID > 0) 
				{
					if(completeAddress != '')
					{
						this.loader = 1;
						if ((addressTypeRadio == 0 || addressTypeRadio == '')) {
							addressTypeRadio = this.userAddressData.type;
						}
	// console.log(addressTypeRadio);
	// return;
						// this.userID = "";
						// if (this.$userData !== null) {
						// 	this.userID = JSON.parse(this.$userData.userID);
						// }
						let formUserAddressData = JSON.stringify({ 
							// userID: this.userID,
																userAddressID: this.userAddressData.userAddID,
																type: addressTypeRadio,
																address: completeAddress,
																landmark: landmark,
																});
						Vue.axios.post('/updateUserAddress', formUserAddressData)
							.then((response)=>{
									this.loader = 0;

									// console.log(response);
									let status = response.status;
									if(status == 200 && response.data.status == 200)
									{
											this.alertShow = true;
											this.alertText = response.data.message;
											this.alertVariants = "success";
											this.alertHead = "Success";
											location.reload();

									}
									// this.cartStatus = response.data.cartdetails;
								}).catch((error) => {
										this.loader = 0;
										console.log(error.response.data.message);
								});
					}
					else
					{
						this.show = true;
						this.alertText = "Please fill all the required * details";
						this.alertVariants = "danger";
						this.alertHead = "Error"
					}
				}
				else
				{
					if (completeAddress != "" && (this.placesApiresult != "" || this.placesApiresult != undefined)) 
					{
						this.loader = 1;
						var country= "";
						var state = "";
						var city = "";
						var pincode ="";
						// console.log(this.placesApiresult);
						for(var i=0; i < this.placesApiresult.address_components.length;i++)
						{
							var addressType = this.placesApiresult.address_components[i].types[0];
							var addressType1 = this.placesApiresult.address_components[i].types[1];
								if(addressType=="locality")
								{
									var city = this.placesApiresult.address_components[i].long_name;
								}
								if(addressType=="administrative_area_level_1")
								{
									var state = this.placesApiresult.address_components[i].long_name;
								}
								if(addressType=="country")
								{
									var country = this.placesApiresult.address_components[i].long_name;
								}
								if(addressType=="postal_code")
								{
									var pincode = this.placesApiresult.address_components[i].long_name;
								}
						}
						// this.userID = "";
						// if (this.$userData !== null) {
						// 	this.userID = JSON.parse(this.$userData.userID);
						// }
						let formUserAddressData = JSON.stringify({ 
							// userID: this.userID,
																type: addressTypeRadio,
																googleAddress: this.addressInput,
																address: completeAddress,
																landmark: landmark,
																pincode: pincode,
																city: city,
																state: state,
																country: country,
																latitude: this.latitude,
																longitude: this.longitude,
																});

						Vue.axios.post('/insertUserAddresses', formUserAddressData)
							.then((response)=>{
									// console.log(response);
									let status = response.status;
									if(status == 200 && response.data.status == 200)
									{
											this.alertShow = true;
											this.alertText = response.data.message;
											this.alertVariants = "success";
											this.alertHead = "Success";
											location.reload();

									}
									// this.cartStatus = response.data.cartdetails;
								}).catch((error) => {
										console.log(error.response.data.message);
								});
					}
					else
					{
						this.alertShow = true;
						this.alertText = "Please fill all the required * details";
						this.alertVariants = "danger";
						this.alertHead = "Error"
					}
				}
		},	
        // idForEditAddress(userAddData)
        // {
        //     this.isEdit = 1;
        //     this.addDataForEdit = userAddData;
        // }
    },
}
</script>
<template>
   <div  class="container-fuild m-0 p-0">
          <div class="alertMsg">
  <b-alert dismissible v-model="alertShow" :variant="alertVariants">
    <h4 class="alert-heading">{{alertHead}}</h4>
    <p>
      {{alertText}}
    </p>
    <!-- <hr>
    <p class="mb-0">
      Whenever you need to, be sure to use margin utilities to keep things nice and tidy.
    </p> -->
  </b-alert>
</div>
<!-- Modal item order -->
  <b-modal id="modal-addAddress"  modal-class="addDeliveryModal" :footer-bg-variant="footerBgVariant" hide-header  size="md" centered>
		<div class="container deliveryAddContainer">
		<div class="clearfix addDeliveryHeader">
            <h4 v-if="userAddressData.userAddID && userAddressData.userAddID != ''">Edit Address</h4>
            <h4 v-else>Add Delivery Address</h4>
        </div>
        <!-- / clearfix -->

        <div class="deliveryLocat">
            <label>Enter your delivery location*</label>
            <vue-google-autocomplete
				ref="googleApiAddress"
				id="map"
				classname="form-control"
				placeholder="Please type your address"
				v-on:placechanged="getAddressData"
				country="in"
				types=""
				:disabled="userAddressData.userAddID > 0 ? true : false"
				:value="userAddressData.userAddID > 0 ? userAddressData.googleAPIAddress : ''"
			>
			</vue-google-autocomplete>
        </div>
        <!-- / deliveryLocat -->

        <div class="completeAdd InfoText">
            <label>Complete Address*</label>
            <input class="form-control" type="text" :value="userAddressData.userAddID > 0 ? userAddressData.address : ''" ref="completeAddress" placeholder="Flat No, Building, Company, Area.">
        </div>
        <!-- / completeAdd -->

        <div class="landmark InfoText">
            <label>Landmark (Optional)</label>
            <input class="form-control" :value="userAddressData.userAddID > 0 ? userAddressData.landmark : ''" ref="landmark" type="text" placeholder="e.g. near Apollo Hospital">
        </div>
        <!-- / completeAdd -->

        <div class="addTypeRadio InfoText">
            <label>Address Type*</label>
            <div class="row">
                <div class="col-3">
                    <label class="container_radio">Home
                        <input type="radio" @change="getAddressType(1)" :checked="userAddressData.userAddID > 0 && userAddressData.type == 1 ? true : true" value="1" name="opt_order">
                        <span class="checkmark"></span>
                    </label>
                </div>
                <div class="col-3">
                    <label class="container_radio">Work
                        <input type="radio" @change="getAddressType(2)" :checked="userAddressData.userAddID > 0 && userAddressData.type == 2 ? true : false" value="2" name="opt_order">
                        <span class="checkmark"></span>
                    </label>
                </div>
                <div class="col-3">
                    <label class="container_radio">Other
                        <input type="radio" @change="getAddressType(3)" :checked="userAddressData.userAddID > 0 && userAddressData.type == 3 ? true : false" value="3" name="opt_order">
                        <span class="checkmark"></span>
                    </label>
                </div>
            </div>

        </div>
        <!-- / addTypeRadio -->
		</div>
		<!-- /Container -->
		<template #modal-footer>
        <div class="w-100">
				<b-button
					size="sm"
					class="float-right btnSave btn btn-warning"
					v-if="loader == 1"
				>
				<img src="../../templateAssests/img/ckgallery/loader.gif" height="25" width="25">
				</b-button>
				<b-button
					size="sm"
					class="float-right btnSave btn btn-warning"
					v-else
					@click="saveAddress"
				>
				<span>
					Save
				</span>
				</b-button>
                <!-- / footer Btn end -->
        </div>
      </template>
  </b-modal> 
    <!-- /Modal item order -->
    </div>
</template>
<style scoped>
    .addDeliveryModal > .modal-dialog > .modal-content > .modal-body {
			padding:30px!important;
		}
        .addDeliveryHeader{
			margin-top: 31px;
			margin-bottom: 27px;
		}
		.InfoText label{
			color:#777;
			font-size: 14px;
			margin: 8px 0px 2px;
		}
</style>
<style>
#modal-addAddress___BV_modal_footer_
{
    border: transparent;
}
.alertMsg{
  position: fixed;
    right: 0px;
    z-index: 500;
    top: 95px;
    width: 22%;
}
@media (max-width:971px)
{
	.alertMsg
	{
    	width: 50%;
	}
}
</style>
